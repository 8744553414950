import { Box, FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import log from "../misc/Logger";
import { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { DrupalEntity, TypeContext, UserType } from "../misc/Types";
import AuthorImageAndName from "./AuthorImageAndName";

interface PropsTeamDropdown {
    handleChange: (value: string) => any,
    // teams: string[],
    selectedTeam: DrupalEntity,
    firstElement?: string,  // optionally first element for team list
    filterOnMyTeams: boolean,
}

export default function TeamDropdown(props: PropsTeamDropdown) {
    log.debug('TeamDropdown');
    const { state } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();

    // protect against setting a value for the Select component that is not yet among the MenuItem values
    // we can not just use team name as value when selecting a team from the dropdown. We have to use uuid because
    // a clubadmin can have several teams with same name - one team is his own and the other teams belong to coaches
    // in the club
    const [menuItems, setMenuItems] = useState<Array<DrupalEntity>>([]);
    const [selectedValue, setSelectedValue] = useState('');
    useEffect(() => {
        setMenuItems(state.allTeams.filter(x => x.relationships.uid.data.id === state.user.data.id || !props.filterOnMyTeams));
    }, [state.allTeams, state.user.data]);
    useEffect(() => {
        if (menuItems.find(x => x.id === props.selectedTeam.id))
            // set the value requested by the parent if it exists in the menuItems
            setSelectedValue(props.selectedTeam.id!);
        else 
            setSelectedValue('')
    }, [menuItems, props.selectedTeam]);

    // console.log(state.allTeams.length, props)

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '450px', margin: '0 auto' }}>
            <FormControl fullWidth>
                <InputLabel
                    id="team-select-label"
                >
                    {t('PracticeProgramForm22')}
                </InputLabel>
                <Select
                    labelId="team-select-label"
                    id="team-select"
                    value={selectedValue}
                    variant="outlined"
                    label={t('PracticeProgramForm22')}
                    onChange={(event) => {
                        // tell the parent that value has changed
                        props.handleChange(event.target.value)
                        // change value in this component
                        setSelectedValue(event.target.value);
                    }}
                    size="small"
                    sx={{ color: 'grey' }}
                >
                    {
                        // Allow for potential static first element in drop down list
                        props.firstElement &&
                        <MenuItem
                            value={props.firstElement}
                            sx={{
                                fontSize: 14,
                                color: theme.palette.text.primary,
                            }}
                            // We must add onClick event handler becuase if "+Add Team" is already selected and we select it again we have to react!
                            onClick={() => {
                                // tell the parent that value has changed
                                props.handleChange(props.firstElement!)
                                // change value in this component
                                setSelectedValue(props.firstElement!);
                            }}
                        >
                            {props.firstElement}
                        </MenuItem>
                    }
                    {menuItems.map((item, index) => (
                        <MenuItem
                            key={index}
                            value={item.id}
                            sx={{
                                color: theme.palette.text.primary,
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <span style={{ fontSize: 15 }}>{item.attributes.title}</span>
                                {
                                    state.user.data.attributes.field_user_type === UserType.clubadmin && !props.filterOnMyTeams &&
                                    <AuthorImageAndName node={item} nameBeforeImage={true} />
                                }
                            </Box>
                        </MenuItem>

                    ))}
                </Select>
            </FormControl >
        </Box>
    )
}