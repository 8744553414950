import { Line } from './DialogPlayBuilder';
import { Typography, Box, IconButton, Tooltip, Button, darken } from '@mui/material';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useMemo, useCallback, useState, useContext, Fragment } from 'react';
import { Context } from '../App';
import { TypeContext } from '../misc/Types';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useTranslation } from 'react-i18next';

interface Props {
    lines: Line[];
    onUpdateLines: (lines: Line[]) => void;
}

export default function PlayAnimationLinesOrder({
    lines,
    onUpdateLines,
}: Props) {
    const { state } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const [showTimeline, setShowTimeline] = useState(!state.portrait);

    const toggleShowTimeline = () => setShowTimeline((prev) => !prev);

    const toggleConcurrentGroup = useCallback(
        (line: Line) => {
            const updatedLines = lines.map((l) =>
                l.id === line.id
                    ? { ...l, sameMomentAsPrevious: l.sameMomentAsPrevious ? undefined : true }
                    : l
            );
            onUpdateLines(updatedLines);
        },
        [lines, onUpdateLines]
    );

    const movementsText: Record<string, string> = {
        dashed: t('PlayAnimationLinesOrder00'),
        straight: t('PlayAnimationLinesOrder01'),
        zigzag: t('PlayAnimationLinesOrder02'),
        bar: t('PlayAnimationLinesOrder03'),
    };

    const groupColors = ['#fff3e0', '#fffde7'];

    const concurrentLineColors = useMemo(() => {
        const sorted = [...lines].sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
        const colorMap = new Map<number | string, string>();
        let currentColor: string | null = null;
        let colorIndex = 0;

        sorted.forEach((line, index) => {
            if (line.sameMomentAsPrevious && index > 0) {
                if (!currentColor) {
                    currentColor = groupColors[colorIndex % groupColors.length];
                    colorIndex++;
                }
                const prevLine = sorted[index - 1];
                colorMap.set(prevLine.id, currentColor);
                colorMap.set(line.id, currentColor);
            } else {
                currentColor = null;
            }
        });

        return colorMap;
    }, [lines]);

    const moveLine = useCallback(
        (index: number, direction: 'up' | 'down') => {
            const sorted = [...lines].sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
            const targetIndex = direction === 'up' ? index - 1 : index + 1;

            if (targetIndex < 0 || targetIndex >= sorted.length) return;

            const reordered = [...sorted];
            const [moved] = reordered.splice(index, 1);
            reordered.splice(targetIndex, 0, moved);

            const updated = reordered.map((line, idx) => ({
                ...line,
                order: idx + 1,
            }));

            onUpdateLines(updated);
        },
        [lines, onUpdateLines]
    );

    return (
        <Fragment>
            {state.portrait && (
                <Button
                    fullWidth
                    color="success"
                    variant="text"
                    onClick={toggleShowTimeline}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 'bold',
                    }}
                >
                    {t('PlayAnimationLinesOrder04')}
                    {showTimeline ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                </Button>
            )}

            {!state.portrait && (
                <Box sx={{ mt: 1 }}>
                    <Typography style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'center', color: '#00398f', display: state.portrait ? 'none' : 'block' }}>
                        {t('PlayAnimationLinesOrder04')}
                        <IconButton onClick={toggleShowTimeline} size="small" sx={{ marginLeft: '2px' }}>
                            {showTimeline ? <KeyboardArrowUpRoundedIcon sx={{ color: '#b0bec5', '&:hover': { color: darken('#b0bec5', 0.3) } }} /> : <KeyboardArrowDownRoundedIcon sx={{ color: '#b0bec5', '&:hover': { color: darken('#b0bec5', 0.3) } }} />}
                        </IconButton>
                    </Typography>
                </Box>
            )}

            {(showTimeline) && (
                <Box style={{ backgroundColor: '#e3f2fd', padding: 8, borderRadius: 3, border: '1px solid #bbdefb' }}>
                    {lines
                        .slice()
                        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                        .map((line, index) => {
                            const isGrouped = concurrentLineColors.has(line.id);
                            const bgColor = isGrouped ? concurrentLineColors.get(line.id) : 'white';
                            return (
                                <Box key={line.id}>
                                <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            p: 1,
                                            backgroundColor: bgColor,
                                            border: '1px solid #ccc',
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                            <Tooltip title={t('PlayAnimationLinesOrder05')} enterDelay={1000}>
                                                <IconButton
                                                    onClick={() => toggleConcurrentGroup(line)}
                                                    size="small"
                                                    sx={{
                                                        color: line.sameMomentAsPrevious ? '#DD6F20' : 'black',
                                                    }}
                                                >
                                                    <RestoreRoundedIcon />
                                                </IconButton>
                                            </Tooltip>

                                            <Typography sx={{ fontSize: '12px', ml: 1, color: 'black' }}>
                                                {movementsText[line.type] || line.type.toUpperCase()} {line.fromId}
                                            </Typography>

                                            <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 0.5 }}>
                                                {index > 0 && (
                                                    <Tooltip title={t('CRUDList01')} enterDelay={1000}>
                                                        <IconButton
                                                            onClick={() => moveLine(index, 'up')}
                                                            size="small"
                                                            sx={{
                                                                color: '#b0bec5',
                                                                '&:hover': {
                                                                    color: darken('#b0bec5', 0.3),
                                                                },
                                                            }}
                                                        >
                                                            <ArrowUpwardIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {index < lines.length - 1 && (
                                                    <Tooltip title={t('CRUDList02')} enterDelay={1000}>
                                                        <IconButton
                                                            onClick={() => moveLine(index, 'down')}
                                                            size="small"
                                                            sx={{
                                                                color: '#b0bec5',
                                                                '&:hover': {
                                                                    color: darken('#b0bec5', 0.3),
                                                                },
                                                            }}
                                                        >
                                                            <ArrowDownwardIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        })}
                </Box>
            )}
        </Fragment>
    );
}