import { useContext, useEffect, useState } from 'react';
import { Box, Divider, Drawer, IconButton, styled, Tooltip, Typography, useTheme } from '@mui/material';
import SortByAlphaRoundedIcon from '@mui/icons-material/SortByAlphaRounded';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionSetOperationMode, OperationMode, TypeContext } from '../misc/Types';
import { Context } from '../App';
import { DRUPALENTITYINIT } from '../misc/Constants';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import SportsBasketballRoundedIcon from '@mui/icons-material/SportsBasketballRounded';
import { getImageURL, logout } from '../misc/Functions';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { AccountCircleRounded, Groups2 } from '@mui/icons-material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { ListItemButton } from '@mui/material';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
// import PlaysIcon from './playbook/PlaysIcon.svg';
// import PlaysIconBlue from './playbook/PlaysIconBlue.svg';


const drawerWidth = 225;
const collapsedDrawerWidth = 57;

const CustomDrawer = styled(Drawer)(({ theme, open }) => ({
  width: open ? drawerWidth : collapsedDrawerWidth,
  // flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : collapsedDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
}));

export default function DrawerLandscape() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useContext(Context) as TypeContext;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedPath, setSelectedPath] = useState<string | null>(location.pathname);
  const [manualToggle, setManualToggle] = useState(false);

  useEffect(() => {
    setSelectedPath(location.pathname);
  }, [location.pathname]);

  const handleDrawerOpen = () => {
    if (!manualToggle) { // Only opens automatically if not in manual mode
      setOpen(true);
    }
  };

  const handleDrawerClose = () => {
    if (!manualToggle) { // Only closes automatically if not in manual mode
      setOpen(false);
    }
  };

  const toggleDrawer = () => {
    setManualToggle(true); // Activates manual mode
    setOpen(!open); // Changes the 'open' state
    setTimeout(() => setManualToggle(false), 300); // Deactivates manual mode after a short delay
  };

  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const openMenu = Boolean(anchorEl);

  // const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };

  const firstSection: any[] = [
    // Home
    {
      text: t('DrawerLandscape00'),
      icon: <HomeOutlinedIcon />,
      selectedIcon: <HomeRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
      action: '/home',
      path: '/home',
      isVisible: !state.portrait,
    },
  ];

  function _playbook(show: boolean) {
    dispatch({ type: 'setPlaybook', playbook: DRUPALENTITYINIT });
    dispatch({ type: 'setOperationMode', operationMode: OperationMode.play });
    dispatch({ type: 'showPracticeProgram', show: show });
    navigate('/icbplays');
  }

  // Items in the secondSection have two actions, action0 and action1. 
  // action0 is 'goto the subject' ie. drills
  // action1 is 'create the subnect', ie create drills
  const secondSection: any[] = [
    // Drills
    {
      text: t('DrawerLandscape01'),
      icon: <SportsBasketballOutlinedIcon />,
      selectedIcon: <SportsBasketballRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
      action0: () => {
        let action: ActionSetOperationMode = { type: 'setOperationMode', operationMode: OperationMode.exercise }
        dispatch(action);
        navigate('/icbdrills');
      },
      action1: () => {
        let action: ActionSetOperationMode = { type: 'setOperationMode', operationMode: OperationMode.exercise }
        dispatch(action);
        navigate('/exercisecreate');
      },
      tooltipCreate: t('DrawerLandscape04'),
      createButtonColor: theme.palette.secondary.main,
      path: '/icbdrills',
      isVisible: true,
    },

    // Practices
    {
      text: t('DrawerLandscape02'),
      icon: <FolderOpenOutlinedIcon />,
      selectedIcon: <FolderRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
      action0: '/practices',
      path: '/practices',
      isVisible: true,
      action1: () => {
        navigate('/icbdrills');
        dispatch({ type: 'showPracticeProgram', show: true });
        dispatch({ type: 'setOperationMode', operationMode: OperationMode.exercise });
      },
      tooltipCreate: t('Exercises01'),
      createButtonColor: theme.palette.secondary.main,
    },

    // Plays
    {
      text: t('DrawerLandscape14'),
      icon: (
        <img
          src={theme.palette.mode === 'dark' ? '/images/playbuilder/PlaysIconWhite.svg' : '/images/playbuilder/PlaysIcon.svg'}
          alt="Prácticas"
          style={{ width: '24px', height: '24px' }}
        />
      ),
      selectedIcon: (
        <img
          src={theme.palette.mode === 'dark' ? '/images/playbuilder/PlaysIconWhite.svg' : '/images/playbuilder/PlaysIconBlue.svg'}
          alt="Prácticas"
          style={{ width: '24px', height: '24px' }}
        />
      ),
      action0: () => {
        let action: ActionSetOperationMode = { type: 'setOperationMode', operationMode: OperationMode.play }
        dispatch(action);
        navigate('/icbplays');
      },
      action1: () => {
        // navigate('/icbdrills')
        let action: ActionSetOperationMode = { type: 'setOperationMode', operationMode: OperationMode.play }
        dispatch(action);
        dispatch({ type: 'setPlay', play: DRUPALENTITYINIT });
        navigate('/createplay');
      },
      tooltipCreate: t('ICBAppBar13'),
      createButtonColor: theme.palette.primary.main,
      path: '/icbplays',
      isVisible: true,
    },

    //Playbooks
    {
      text: t('DrawerLandscape15'),
      icon: <ArticleOutlinedIcon />, // Replace with your desired icon
      selectedIcon: <ArticleIcon sx={{ color: theme.palette.primary.main }} />,
      action0: () => navigate('/myplaybooks'),
      path: '/myplaybooks',
      isVisible: true,
      action1: () => _playbook(true),
      tooltipCreate: t('ICBAppBar14'),
      createButtonColor: theme.palette.primary.main,
    },

  ];

  const thirdSection: any[] = [
    //Concepts
    {
      text: t('DrawerLandscape13'),
      icon: <LightbulbOutlinedIcon />,
      selectedIcon: <LightbulbIcon sx={{ color: theme.palette.secondary.main }} />,
      action: '/concepts',
      path: '/concepts',
      isVisible: true,
    },
    //Reports
    {
      text: t('DrawerLandscape03'),
      icon: <InsertChartOutlinedIcon />,
      selectedIcon: <InsertChartIcon sx={{ color: theme.palette.secondary.main }} />,
      action: '/formgetpracticereport',
      path: '/formgetpracticereport',
      isVisible: true
    },
    //Club documents
    {
      text: t('DrawerLandscape11'),
      icon: <ArticleOutlinedIcon />,
      selectedIcon: <ArticleIcon sx={{ color: theme.palette.secondary.main }} />,
      action: '/clubdocuments',
      path: '/clubdocuments',
      isVisible: ['clubadmin', 'club'].includes(state.user.data.attributes.field_user_type)
    },
    //Categories
    {
      text: t('DrawerLandscape05'),
      icon: <SortByAlphaRoundedIcon />,
      selectedIcon: <SortByAlphaRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
      action: '/groups',
      path: '/groups',
      isVisible: ['pro', 'clubadmin'].includes(state.user.data.attributes.field_user_type)
    },
    //My teams
    {
      text: t('DrawerLandscape10'),
      icon: <GroupWorkOutlinedIcon />,
      selectedIcon: <GroupWorkIcon sx={{ color: theme.palette.secondary.main }} />,
      action: '/myteams',
      path: '/myteams',
      isVisible: true,
    },
    //Coaches
    {
      text: t('DrawerLandscape06'),
      icon: <GroupsOutlinedIcon />,
      selectedIcon: <Groups2 sx={{ color: theme.palette.secondary.main }} />,
      action: '/coaches',
      path: '/coaches',
      isVisible: state.user.data.attributes.field_user_type === 'clubadmin'
    },
  ];

  return (
    <CustomDrawer
      variant="permanent"
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
      open={open}
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme.spacing(0, 1),
          ...theme.mixins.toolbar,
        }}
      >
        {open && (
          <Box mt="7px" ml="10px">
            <img
              height={32}
              src={getImageURL(theme.palette.mode === 'dark' ? 'logoICBWhite.webp' : 'logoICBBlue.webp')}
              alt="Logo"
            />
          </Box>
        )}

        <IconButton onClick={toggleDrawer} aria-label="toggle drawer">
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        {/* First section */}
        {firstSection.map((item, index) => (
          item.isVisible && (
            <Box paddingRight={1} paddingLeft={1} paddingBottom={0.5} key={index}>
              <ListItemButton
                onClick={() => {
                  typeof item.action === 'function' ? item.action() : navigate(item.action);
                }}
                selected={selectedPath === item.path}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: open ? 'flex-start' : 'center',
                  borderRadius: 2,
                  width: '100%',
                  padding: '8px',
                }}
              >
                {selectedPath === item.path ? item.selectedIcon : item.icon}
                {open && (
                  <Typography
                    sx={{
                      fontSize: 14,
                      whiteSpace: 'nowrap',
                      marginLeft: 1,
                      fontWeight: selectedPath === item.path ? 'bold' : 'normal',
                      color: selectedPath === item.path ? theme.palette.secondary.main : '',
                    }}
                  >
                    {t(item.text)}
                  </Typography>
                )}
              </ListItemButton>
            </Box>
          )
        ))}

        {!state.portrait &&
          <Divider sx={{ marginY: 0.5, marginX: 1 }} />
        }

        {/* Second section */}
        {secondSection.map((item, index) => (
          item.isVisible && (
            <Box paddingRight={1} paddingLeft={1} paddingBottom={0.5} key={index} display="flex" alignItems="center">
              <ListItemButton
                onClick={() => {
                  typeof item.action0 === 'function' ? item.action0() : navigate(item.action0);
                }}
                selected={selectedPath === item.path}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: open ? 'flex-start' : 'center',
                  borderRadius: 2,
                  width: '100%',
                  padding: '8px',
                }}
              >
                {selectedPath === item.path ? item.selectedIcon : item.icon}
                {open && (
                  <Typography
                    sx={{
                      fontSize: 14,
                      whiteSpace: 'nowrap',
                      marginLeft: 1,
                      fontWeight: selectedPath === item.path ? 'bold' : 'normal',
                      color: selectedPath === item.path ? item.createButtonColor : '',
                    }}
                  >
                    {t(item.text)}
                  </Typography>
                )}
              </ListItemButton>
              {open && (
                <IconButton
                  onClick={() => {
                    // Acción del IconButton
                  }}
                  aria-label="acción adicional"
                  sx={{ marginLeft: open ? 1 : 0 }}
                >
                  <Tooltip title={t(item.tooltipCreate)} placement="right">
                    <AddBoxRoundedIcon
                      onClick={item.action1}
                      sx={{ color: item.createButtonColor }}
                    />
                  </Tooltip>
                </IconButton>
              )}
            </Box>
          )
        ))}

        <Divider sx={{ marginY: 0.5, marginX: 1 }} />

        {/* Third section */}
        {thirdSection.map((item, index) => (
          item.isVisible && (
            <Box paddingRight={1} paddingLeft={1} paddingBottom={0.5} key={index}>
              <ListItemButton
                onClick={() => {
                  typeof item.action === 'function' ? item.action() : navigate(item.action);
                }}
                selected={selectedPath === item.path}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: open ? 'flex-start' : 'center',
                  borderRadius: 2,
                  width: '100%',
                  padding: '8px',
                }}
              >
                {selectedPath === item.path ? item.selectedIcon : item.icon}
                {open && (
                  <Typography
                    sx={{
                      fontSize: 14,
                      whiteSpace: 'nowrap',
                      marginLeft: 1,
                      fontWeight: selectedPath === item.path ? 'bold' : 'normal',
                      color: selectedPath === item.path ? theme.palette.secondary.main : '',
                    }}
                  >
                    {t(item.text)}
                  </Typography>
                )}
              </ListItemButton>
            </Box>
          )
        ))}
      </Box>

      {/* My account all the way down */}
      <Box paddingRight={1} paddingLeft={1} paddingBottom={1} display="flex" alignItems="center">
        <ListItemButton
          onClick={() => {
            navigate('/profile');
          }}
          selected={selectedPath === '/profile'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: open ? 'flex-start' : 'center',
            borderRadius: 2,
            width: '100%',
            padding: '8px',
          }}
        >
          {selectedPath === '/profile' ? <AccountCircleRounded sx={{ color: theme.palette.secondary.main }} /> : <AccountCircleRounded />}
          {open && (
            <Typography
              sx={{
                fontSize: 14,
                whiteSpace: 'nowrap',
                marginLeft: 1,
                fontWeight: selectedPath === '/profile' ? 'bold' : 'normal',
                color: selectedPath === '/profile' ? theme.palette.secondary.main : '',
              }}
            >
              {t('DrawerLandscape07')}
            </Typography>
          )}
        </ListItemButton>
        {open && (
          <IconButton
            onClick={() => logout(state, dispatch, navigate)}
            aria-label="acción adicional"
          >
            <Tooltip title={t('DrawerLandscape16')} placement="right">
              <LogoutRoundedIcon />
            </Tooltip>
          </IconButton>
        )}
      </Box>
    </CustomDrawer>
  );
}
