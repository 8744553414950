import { TypeState, Action, ExerciseSelected, DrupalEntity, LocalStorageICB, OperationMode } from "./Types";
import log from "./Logger";
import { DEFAULT_GROUP_EXERCISE, DEFAULT_GROUP_PLAY, DRUPALENTITYINIT, ENTITY_TYPE_TO_STATE_PROPERTY, ICB_USER_ID, SHOW_ALL_GROUP, UISETTINGSINIT } from "./Constants";
import i18next from 'i18next'
import { lng } from "./Functions";
import { randomId } from "@mui/x-data-grid-generator";

// We must use texts from translation files
// Here we are not in a react compoent envronment so we have to import translation like below
i18next.init(
    {
        fallbackLng: "en",
        lng: lng(),
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: `${import.meta.env.BASE_URL}i18n/{{lng()}}.json`,
        },
    }
)

/*
We have 2 lists of nodes
Run through second list. For each item check if same id is in first list.
If yes, then update element in first list. Otherwise, add to first list.
*/
function addNewRows(type: string, cur: Array<DrupalEntity>, add: Array<DrupalEntity>): Array<DrupalEntity> {
    add.filter(x => x.type === type).forEach(y => {
        let curItem = cur.find(z => z.id === y.id)
        if (curItem) {
            cur[cur.indexOf(curItem)] = y
        } else {
            cur.push(y)
        }
    })
    return [...cur] // use the spread operator to make sure that the var changes and useEffects on the var is triggered
}

// // Compare a stored practice by field_team_name, field_practice_date
// function compareFnAllPractices(a: DrupalEntity, b: DrupalEntity) {
//     let aUserAttr = JSON.parse(a.attributes.body.value).userDataAttributes
//     let bUserAttr = JSON.parse(b.attributes.body.value).userDataAttributes
//     if (aUserAttr.field_team_name < bUserAttr.field_team_name) {
//         return -1;
//     } else if (aUserAttr.field_team_name > bUserAttr.field_team_name) {
//         return 1;
//     } else if (new Date(aUserAttr.field_practice_date) < new Date(bUserAttr.field_practice_date)) {
//         return -1;
//     } else if (new Date(aUserAttr.field_practice_date) > new Date(bUserAttr.field_practice_date)) {
//         return 1;
//     }
//     // a must be equal to b
//     return 0;
// }

// Trick to add extra parameter to the sort function. 
// Ie. state.configuration[0].attributes.field_days_to_flag_new_exercises
// See https://stackoverflow.com/questions/14491695/how-can-i-pass-more-parameters-to-a-custom-sort-function
function compareFNExercisesAddDays(exerciseNewDays: number) {
    return function compareFNExercises(a: DrupalEntity, b: DrupalEntity) {
        let nowMinusXDays = Date.now() - exerciseNewDays * 24 * 60 * 60 * 1000;
        if (Date.parse(a.attributes.created) > nowMinusXDays && Date.parse(a.attributes.created) > Date.parse(b.attributes.created))
            // a is created within the last FLAG_DAYS_FOR_NEW_EXERCISE days and create after b
            return -1
        if (Date.parse(b.attributes.created) > nowMinusXDays && Date.parse(b.attributes.created) > Date.parse(a.attributes.created))
            // b is created within the last FLAG_DAYS_FOR_NEW_EXERCISE days and create after a
            return 1
        if (a.relationships.field_exercise_video.data && !b.relationships.field_exercise_video.data)
            // a has video. b has no video
            return -1
        if (!a.relationships.field_exercise_video.data && b.relationships.field_exercise_video.data)
            // a has no video. b has video
            return 1
        if (a.attributes.title < b.attributes.title)
            // a before b
            return -1
        if (a.attributes.title > b.attributes.title)
            // a after b
            return 1
        return 0;
    }
}

// Sort exercise groups
function sortGroups(groups: Array<DrupalEntity>, sortOrderNidList: string): Array<DrupalEntity> {
    // On code change and auto refresh we have to make sure we remove first group, 'Show ALl',
    // because we add 'Show All' later unconditionally!
    groups = groups.filter(x => x.attributes.drupal_internal__nid);
    // Sort groups
    let groupsSorted: Array<DrupalEntity> = [];
    // Get sort order from user profile
    let orderArray = sortOrderNidList.split(',');
    // Run through group drupal_internal__nid in sort order and push to array of groups
    orderArray.forEach((element: string) => {
        let group = groups!.find(x => x.attributes.drupal_internal__nid.toString() === element)
        if (group) {
            groupsSorted.push(group);
        }
    });
    // Add groups that have not been added so far (not part of sort order)
    groups.filter(x => !groupsSorted.find(y => y === x)).forEach(x => groupsSorted.push(x))

    // Add static 'Show All' group.
    groupsSorted.splice(0, 0, {
        type: '',
        id: SHOW_ALL_GROUP,
        attributes: { title: i18next.t('Reducer00') },
        relationships: {},
    })

    // Return sorted groups
    return groupsSorted;
}

// store selected data from current practice so user can restart app and still hold on to selected data
function setLocalStorage(state: TypeState) { //localStorageICB: LocalStorageICB) {
    const store = JSON.stringify({ curPractice: state.curPractice, uid: state.user.login.current_user.uid })
    localStorage.setItem(`ìcb_${__APP_VERSION__}`, store);
}

export function reducer(state: TypeState, action: Action): TypeState {
    log.debug(`reducer, ${action.type}`);

    // if (action.type === 'setDisabled') {
    //     return { ...state, disabled: action.disabled }
    if (action.type === 'setLoggedIn') {
        return {
            ...state,
            loggedIn: action.userType
        }
    } else if (action.type === 'setUserData') {
        return {
            ...state,
            user: {
                ...state.user,
                data: action.userData
            }
        }
    } else if (action.type === 'setLocale') {
        // For better model pls. see https://stackoverflow.com/questions/35728632/react-i18next-and-correct-way-of-changing-language
        // i18n.changeLanguage(formatLanguage(action.locale));
        // We have to empty array with selected exercises because selected exercises belong to the previouse locale - BUT THAT DOES NOT MATTER!!
        return {
            ...state,
            user: {
                ...state.user,
                locale: action.locale
            },
        }
    } else if (action.type === 'setSelectedExercises') {
        return {
            ...state,
            curPractice: { ...state.curPractice, selectedExercises: action.selectedExercises },
        }
    } else if (action.type === 'setSelectedExercisePool') {
        // If currently selected group is a Club Admin Defined Group and we are going to display a group in icb exercise pool then
        // we have to adjust currently selected group because Club Admin Defined Groups are not visible when displaying
        // icb exercise pool
        const defaulGroup = state.operationMode === OperationMode.exercise ? DEFAULT_GROUP_EXERCISE : DEFAULT_GROUP_PLAY
        let selectedGroup = state.selectedGroup;
        if (selectedGroup &&                                                                // we have a selected group
            state.allGroups.indexOf(selectedGroup) !== 0 &&                                 // and it is not the first group - Show All
            action.pool === 'icb' &&                                                        // and we are going to show pool icb
            selectedGroup.relationships.uid?.data.id !== ICB_USER_ID)                      // and current group owner is not icoachbasketball.com
            selectedGroup = state.allGroups.find(x => x.id === defaulGroup)!;               // so change group to default group
        return {
            ...state,
            selectedExercisePool: action.pool,
            selectedGroup: selectedGroup
        }
    } else if (action.type === 'setState') {
        return { ...action.state }
    } else if (action.type === 'setSelectedGroup') {
        return {
            ...state,
            selectedGroup: action.group,
        }
    } else if (action.type === 'setBackdrop') {
        return { ...state, backdrop: action.diff }
    } else if (action.type === 'setAwaitPaymentProcessing') {
        return { ...state, awaitPaymentProcessing: action.await }
    } else if (action.type === 'setPractice') {
        const selectedExercises = action.practice.selectedExercises.map((item: ExerciseSelected) => {
            return {
                ...item,
                /*
                we only save the drupal_internal__nid of the exercise in the content node practice. 
                Here we convert to a true exercise and not just drupal_internal__nid
                If we can not find an exericse based on the drupal_internal__nid we keep a note of the drupal_internal__nid
                so we can try later
                */
                exercise: state.allExercises.find(x => x.attributes.drupal_internal__nid === item.drupal_internal__nid)
                    || { ...DRUPALENTITYINIT, attributes: { drupal_internal__nid: item.drupal_internal__nid } },
                // generate random id for selected exercise when setting current practice en block
                uuid: randomId(),
            }
        })
        const stateLocal = {
            ...state,
            curPractice: {
                ...action.practice,
                selectedExercises: selectedExercises,
            }
        }
        setLocalStorage(stateLocal)
        return stateLocal
    } else if (action.type === 'setConfirm') {
        // get a note in the log of user confirmation prompts
        if (action.confirm.text)
            log.info(`reducer, ${action.type} - ${action.confirm.text}`);
        return { ...state, confirm: action.confirm }
    } else if (action.type === 'showPracticeProgram') {
        return {
            ...state,
            showPracticeProgram: action.show,
        };
        // } else if (action.type === 'setAppDebug') {
        //     return { ...state, debug: action.value };
    } else if (action.type === 'setOnGlobalState') {
        return { ...state, obj: action.obj };
    } else if (action.type === 'resizeOrRotate') {
        return {
            ...state,
            portrait: action.portrait,
        };
    } else if (action.type === 'showWellcomePresentation') {
        return { ...state, showWelcomePresentation: action.show };
        // } else if (action.type === 'setOneTimeExecuted') {
        //     return { ...state, oneTimeExecuted: action.done };
    } else if (action.type === 'setLogin') {
        // get unsaved practice from browser app cache for current version of app
        let storage: LocalStorageICB = {};
        try {
            storage = JSON.parse(localStorage.getItem(`ìcb_${__APP_VERSION__}`) || '{}')
            if (!storage.uid || storage.uid !== action.login.current_user.uid)
                storage = {}
        } catch {
            log.error(' local storage is not parsable: ' + localStorage.getItem(`ìcb_${__APP_VERSION__}`));
        }
        return {
            ...state,
            loggedIn: 1,
            user: {
                ...state.user,
                login: action.login
            },
            curPractice: storage.curPractice ? { ...storage.curPractice, date: new Date(storage.curPractice.date) } : state.curPractice,
            showPracticeProgram: storage.showPracticeProgram ? storage.showPracticeProgram : state.showPracticeProgram,
        };
        // } else if (action.type === 'setBeforeinstallprompt') {
        //     return { ...state, eventBeforeinstallprompt: action.event };
    } else if (action.type === 'setShowIntro') {
        return { ...state, showIntro: action.show };
        // } else if (action.type === 'setExerciseDetails') {
        //     return { ...state, exerciseDetails: action.exercise };
    } else if (action.type === 'setExerciseIDLastCRUD') {
        return { ...state, exerciseIDLastCRUD: action.id };
    } else if (action.type === 'setFromApp') {
        return {
            ...state,
            fromApp: action.fromApp,
        };
    } else if (action.type === 'setClubInvite') {
        return { ...state, clubInvite: action.clubInvite };
    } else if (action.type === 'setCRUDListSelectedValues') {
        return { ...state, CRUDListSelectedValues: action.selectedValues }
    } else if (action.type === 'setGotUsersGroupsExercises') {
        return { ...state, gotUsersGroupsExercises: state.gotUsersGroupsExercises + 1 };
    } else if (action.type === 'setPracticeProgramDirty') {
        return {
            ...state,
            curPractice: {
                ...state.curPractice,
                dirty: action.dirty,
            }
        }
    } else if (action.type === 'setConfiguration') {
        return {
            ...state,
            configuration: action.configuration
        };
    } else if (action.type === 'goHome') {
        return {
            ...state,
            selectedExercisePool: 'icb',
            selectedGroup: state.allGroups[1],
            showPracticeProgram: false,
        };
    } else if (action.type === 'sortGroups') {
        // Set exercise/play group sort order
        // all users have their own sorting. If a club user has no sorting then use sorting of club admin 
        const field_my_club_admin = state.allUsers.find(x => x.id === state.user.data.relationships.field_my_club_admin.data?.id)
        const sortOrder = state.user.data.attributes.field_groups_sort                                    // users sort order
            || (field_my_club_admin && field_my_club_admin.attributes.field_groups_sort)                  // users clubadmin sort order
            || state.allUsers.find(x => x.id === ICB_USER_ID)?.attributes.field_groups_sort               // platform sort order
            || ''                                                                                         // no sort order
        const groupsSorted = sortGroups(state.allGroups, sortOrder);
        // don't update state if sort order not changed - needed because of useEffect in Groups
        if (JSON.stringify(groupsSorted) === JSON.stringify(state.allGroups)) {
            return state
        } else {
            return {
                ...state,
                allGroups: groupsSorted,
            }
        }
    } else if (action.type === 'setContentEntity') {
        // get name of property on global state that holds this content entity
        const property = ENTITY_TYPE_TO_STATE_PROPERTY[action.contentType]
        log.debug('setContentEntity', action.contentType, action.data.length, 'cur. length of allFiles: ', state.allFiles.length)
        // do we come with user profile data about the current user? If so update current user
        let curUserData
        if (action.contentType == 'user--user')
            curUserData = action.data.find((x: DrupalEntity) => x.attributes.drupal_internal__uid === state.user.login.current_user.uid)
        // if initialload then list is cleared before using new list. Otherwise we add list to the existing list - with dublicate control
        const propertyValue: DrupalEntity[] = action.initialLoad ? action.data : addNewRows(action.contentType, state[property as keyof TypeState], action.data)

        // special cases depending on what content type has been retrieved
        // all exercises must be sorted
        if (action.contentType === 'node--exercise')
            propertyValue.sort(compareFNExercisesAddDays(state.configuration[0].attributes.field_days_to_flag_new_exercises))
        // set team of current practice if not picked up from storage
        let practice = { ...state.curPractice }
        if (action.contentType === 'node--team'
            && !practice.team.id
            && propertyValue.find(x => x.relationships.uid.data.meta.drupal_internal__target_id === state.user.login.current_user.uid))
            practice.team = propertyValue.find(x => x.relationships.uid.data.meta.drupal_internal__target_id === state.user.login.current_user.uid)!
        // if current playbook is updated, ie. by ICBPlaybookController we have to set it again
        const updateCurPlaybook =
            action.contentType === 'node--playbook'
            && !action.initialLoad
            && state.curPlaybook.id === action.data[0].id

        // initially after app start up selected group is the DEFAULT_GROUP_EXERCISE
        let selectedGroup = { ...state.selectedGroup }
        if (action.contentType === 'node--group' && action.initialLoad)
            selectedGroup = action.data.find(x => x.id === DEFAULT_GROUP_EXERCISE)!

        return {
            ...state,
            [property]: propertyValue,
            user: curUserData ? { ...state.user, data: curUserData } : state.user,
            uiSettings: curUserData ? JSON.parse(curUserData.attributes.field_ui_settings) || UISETTINGSINIT : state.uiSettings,
            curPractice: practice.team.id ? practice : state.curPractice,
            curPlaybook: updateCurPlaybook ? action.data[0] : state.curPlaybook,
            selectedGroup: selectedGroup.id === state.selectedGroup.id ? state.selectedGroup : selectedGroup, // funny statement to ensure that state.selectedGroup does not change for no reason!
        }
    } else if (action.type === 'delContentEntity') {
        const property = ENTITY_TYPE_TO_STATE_PROPERTY[action.contentType]
        return {
            ...state,
            [property]: [...state[property as keyof TypeState].filter((x: DrupalEntity) => x.id !== action.id)],
        }
    } else if (action.type === 'setPracticesRetrieved') {
        return {
            ...state,
            practicesRetrieved: true,
        }
    } else if (action.type === 'setOperationMode') {
        const selectedGroupLocal = action.operationMode === OperationMode.exercise
            ? state.allGroups.find(x => x.id === DEFAULT_GROUP_EXERCISE)!
            : state.allGroups.find(x => x.id === DEFAULT_GROUP_PLAY)!
        return {
            ...state,
            operationMode: action.operationMode,
            selectedGroup: selectedGroupLocal || state.selectedGroup, // use || .... because at start up we don't have groups and we can not risk a null value for selectedGroup
        }
        // } else if (action.type === 'setSelectedPlays') {
        //     return {
        //         ...state,
        //         selectedPlays: action.plays
        //     }
    } else if (action.type === 'setUISettings') {
        return {
            ...state,
            uiSettings: action.uiSettings,
        }
    } else if (action.type === 'setPlaybook') {
        return {
            ...state,
            curPlaybook: action.playbook,
        }
    } else if (action.type === 'setPlay') {
        return {
            ...state,
            curPlay: action.play,
        }
    } else if (action.type === 'setAppBarHeight') {
        return {
            ...state,
            appBarHeight: action.height,
        }
    } else if (action.type === 'setAppBarShowTabs') {
        return {
            ...state,
            appBarShowTabs: action.show,
        }
    }

    // Should NOT come here!
    log.error("Error. NO GO. Unhandled action: ", action.type)

    return state;
}
