import Grid2 from '@mui/material/Unstable_Grid2';
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react"
import { Box, Button, CircularProgress, Typography, } from '@mui/material';
import { DrupalEntity, JSONAPITypeId, OperationMode, TypeContext } from '../misc/Types';
import { Context } from '../App';
import ExerciseCard from './ExerciseCard';
import PlayCard from './PlayCard'
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { LockOpen } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import SearchField from './SearchField';
import { getClubAdmin } from '../misc/Functions';
import { ICB_USER_ID, SHOW_ALL_GROUP } from '../misc/Constants';

export default function Exercises() {
  const { state } = useContext(Context) as TypeContext;
  const navigate = useNavigate();
  const { t } = useTranslation();
  log.debug('Exercises');
  const theme = useTheme();

  // infinite scrolling for exercise list. See links below
  // https://www.youtube.com/watch?v=NZKUirTtxcg
  // https://github.com/WebDevSimplified/React-Infinite-Scrolling
  // https://claritydev.net/blog/typescript-typing-react-useref-hook
  const pageSize = 10; // render exercises in blocks of "pageSize"
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const observer = useRef<IntersectionObserver | null>(null);
  const lastExerciseElementRef = useCallback((node: any) => {
    // console.log('lastExerciseElementRef = useCallback');
    // console.log(node);
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        // console.log('lastExerciseElementRef, last element on display!');
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node);
  }, [])

  useEffect(() => {
    setPageNumber(1);
  }, [state.selectedGroup, state.selectedExercisePool])

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  // Exercises in selected group and selected pool
  const exercisesOrig = () => {
    // return if no groups have been retrieved or no group selected
    if (state.allGroups.length === 0 || !state.selectedGroup?.attributes?.title)
      return (<></>)

    // show cards with exercises or with plays
    let sourceList: DrupalEntity[] = []
    let field_group = ''
    let field_favorites = ''
    if (state.operationMode === OperationMode.exercise) {
      sourceList = state.allExercises
      field_group = 'field_group'
      field_favorites = 'field_favorite_exercises'
    } else if (state.operationMode === OperationMode.play) {
      sourceList = state.allPlays
      field_group = 'field_play_group'
      field_favorites = 'field_favorite_plays'
    }

    // get list of exercises or plays based on discontinued and pool
    let exer = sourceList
      .filter(x => !x.attributes.field_discontinued)
      .filter(x =>
        // pool is icb and exercise/play created by icb
        (state.selectedExercisePool === 'icb' && x.relationships.uid.data.id === ICB_USER_ID)
        // pool is club and exercise/play created by current clubadmin
        || (state.selectedExercisePool === 'club' && x.relationships.uid.data.id === getClubAdmin(state))
        // pool is club and exercise/play is in favorites and current user is clubadmin
        || (state.selectedExercisePool === 'club' && (state.user.data.relationships[field_favorites].data.find((y: JSONAPITypeId) => y.id === x.id)) && state.user.data.attributes.field_user_type === 'clubadmin')
        // pool is mine/favorites and exercise/play created by current user
        || (state.selectedExercisePool === 'mine' && (x.relationships.uid.data.id === state.user.data.id))
        // pool is mine/favorites and exercise/play is in favorites
        || (state.selectedExercisePool === 'mine' && (state.user.data.relationships[field_favorites]?.data.find((y: JSONAPITypeId) => y.id === x.id)))
      )
      // show specifik group or show all
      .filter(x => x.relationships[field_group].data.id === state.selectedGroup.id || state.selectedGroup.id === SHOW_ALL_GROUP)

    // Filter exercises based on search query
    if (searchQuery) {
      exer = exer.filter(x => x.attributes.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    // If no exercises available, return a button with the message
    if (exer.length === 0) {
      return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', textAlign: 'center' }}>
          <Box display={state.exercisesRetrivedInitially ? 'none' : 'block'} marginTop={2}>
            <CircularProgress size={25} />
          </Box>
          {state.operationMode === OperationMode.exercise &&
            <Typography sx={{ paddingTop: 2, color: theme.palette.text.secondary, fontSize: { xs: 13, sm: 16 } }}>
              {state.exercisesRetrivedInitially ? t('Exercises04') : t('Exercises07')}
            </Typography>
          }
          {state.operationMode === OperationMode.play &&
            <Typography sx={{ paddingTop: 2, color: theme.palette.text.secondary, fontSize: { xs: 13, sm: 16 } }}>
              {state.exercisesRetrivedInitially ? t('Exercises08') : t('Exercises09')}
            </Typography>
          }
        </Box>
      );
    }

    // infinite scrolling
    return (
      // extract the pageNumber * pageSize first elements and put a ref on the last element
      exer.splice(0, pageNumber * pageSize).map((node: DrupalEntity, index: number) => {
        return (
          <Grid2
            key={index}
            xs={6}
            sm={4}
            md={4}
            xl={3}
            ref={index === pageNumber * pageSize - 1 ? lastExerciseElementRef : null}>
            {
              state.operationMode === OperationMode.exercise
                ? <ExerciseCard
                  key={index}
                  exercise={node}
                />
                : <PlayCard
                  key={index}
                  play={node}
                />
            }
          </Grid2>
        )
      })
    )
  }

  return (
    <Box style={{ marginBottom: 150, marginTop: 10 }}>

      {/* WE USE THIS 3 BOXES BECAUSE IN THIS WAY WE CAN HIDE THE SEARCHFIELD BELOW THE ICBAPPBAR.
      WE START THE VIEW ON THE DIVIDER WITH THE ID= initialViewExercises */}
      {/* <Divider sx={{ borderColor: 'transparent' }} id="initialViewExercisesLandscape" />
      <Box style={{ marginTop: 60 }} />
      <Divider sx={{ borderColor: 'transparent' }} id="initialViewExercisesPortrait" />
      <Box style={{ marginTop: 60 }} /> */}

      {/* Search Field */}
      <Box width="95%" maxWidth="850px" margin={'auto'}>
        <SearchField onSearch={handleSearch} />
      </Box>

      {/* Exercises/plays in selected group and selected pool */}
      <Grid2 container margin={state.portrait ? '12px' : 0} spacing={state.portrait ? 1 : 3}>
        {exercisesOrig()}
      </Grid2>
      {
        state.user.data.attributes.field_user_type === 'free' && state.selectedExercisePool === "icb" &&
        <Fragment>
          <Box display="flex" justifyContent="center" width="100%">
            <Button
              onClick={() => navigate('/setsubscription')}
              variant="contained"
              color={state.operationMode === OperationMode.exercise ? "secondary" : "primary"}
              style={{ textTransform: 'none', fontWeight: 'bold', marginTop: 15, borderRadius: 20 }}
            >
              <LockOpen style={{ marginRight: 10 }} />
              {state.operationMode === OperationMode.exercise ? t('Generel16') : t('Generel22')}
            </Button>
          </Box>
        </Fragment>
      }
    </Box >
  );
}