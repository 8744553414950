import { Fragment, useContext } from "react"
import { DrupalEntity, TypeContext, UserType } from "../misc/Types";
import { Context } from "../App";
import { Box, Typography } from "@mui/material";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { CDN } from "../misc/Constants";

interface PropsAuthorImageAndName {
    node: DrupalEntity,
    nameBeforeImage?: boolean,
}

export default function AuthorImageAndName(props: PropsAuthorImageAndName) {
    const { state } = useContext(Context) as TypeContext;

    let author
    // try find orignal author id
    let authorID = props.node.relationships.field_original_author?.data?.id
    if (authorID) {
        // we have original author id - find original author
        author = state.allUsers.find(x => x.id === authorID)
        if (!author) {
            if (state.user.data.attributes.field_user_type === UserType.club)
                // no original author and current user is club user - use clubadmin as author
                author = state.allUsers.find(x => x.id === state.user.data.relationships.field_my_club_admin.data.id)
            else
                // no original author and current user is not club user - use user as author
                author = state.user.data
        }
    } else {
        // no original author id - use creator of node as author
        author = state.allUsers.find(x => x.id === props.node.relationships.uid.data.id)!
    }

    // return if we were not able to find author. This will be the case after portrait login and fast click on drills in lower app bar
    if (!author)
        return(<Fragment></Fragment>)

    const fileIDAuthorImage = author.relationships.user_picture?.data?.id || '';
    const authorImage = fileIDAuthorImage && state.allFiles.find(x => x.id === fileIDAuthorImage)?.attributes.uri.url;

    function getAuthorName() {
        return (
            <Typography sx={{ flexGrow: 1, fontSize: { xs: 11, sm: 12 }, marginLeft: 0.5 }} marginTop={'auto'} marginBottom={'auto'}>
                {`${author!.attributes.field_first_name} ${author!.attributes.field_last_name}`}
            </Typography>
        )
    }
    function getAuthorIamge() {
        if (authorImage)
            return (
                <img
                    src={`${CDN}${authorImage}.webp`}
                    height="32"
                    width="32"
                    style={{ borderRadius: "50%", height: '32px', width: '32px' }}
                    alt="Imagen del Autor"
                />
            )
        else
            return (
                <AccountCircleRoundedIcon />
            )
    }

    if (props.nameBeforeImage)
        return (
            <Box display={'flex'}>
                {getAuthorName()}
                {getAuthorIamge()}
            </Box>
        )
    else
        return (
            <Box display={'flex'}>
                {getAuthorIamge()}
                {getAuthorName()}
            </Box>
        )
}