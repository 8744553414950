import { Button, Typography, Grid, Box, darken } from '@mui/material';
import { useContext } from 'react';
import { Context } from '../App';
import { TypeContext } from '../misc/Types';
import SportsBasketballRoundedIcon from '@mui/icons-material/SportsBasketballRounded';
import { useTranslation } from 'react-i18next';
import { Player } from './DialogPlayBuilder';
import DeleteIcon from '@mui/icons-material/Delete';


interface ActionButtonsProps {
    selectedPlayer: Player | null;
    createLine: (type: 'straight' | 'zigzag' | 'dashed' | 'bar') => void;
    handleCreateNewFrame: () => void;
    setIsModalOpen: (isOpen: boolean) => void;
    handleDeleteLine: () => void;
    handleToggleBall: () => void;
    handleSavePlay: () => void;
    hasConnectedLines: boolean;
    // handleSendPlay: () => void;
}

export default function PlayBuilderActionButtons({
    selectedPlayer,
    createLine,
    //handleCreateNewFrame,
    //setIsModalOpen,
    handleDeleteLine,
    handleToggleBall,
    hasConnectedLines,
    //handleSavePlay,
    // handleSendPlay,
}: ActionButtonsProps) {
    const { state } = useContext(Context) as TypeContext;
    const { t } = useTranslation();

    return (
        <>
            <div style={{ marginBottom: '10px' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '10px', textAlign: 'center', color: '#00398f', display: state.portrait ? 'none' : 'block' }}>
                    {t('ActionButtons00')}
                </Typography>


                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            size={state.portrait ? 'small' : 'medium'}
                            fullWidth
                            onClick={() => createLine('straight')}
                            sx={{
                                textTransform: 'none',
                                fontWeight: 'bold',
                                backgroundColor: '#00398f',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: darken('#00398f', 0.1),
                                    color: 'white',
                                },
                            }}
                        >
                            <img src={'/images/playbuilder/straightLight.svg'} alt="Straight Line" style={{ width: '40px', height: 'auto' }} />
                            {t('ActionButtons01')}
                        </Button>
                    </Grid>

                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            size={state.portrait ? 'small' : 'medium'}
                            fullWidth
                            onClick={() => createLine('zigzag')}
                            sx={{
                                textTransform: 'none',
                                fontWeight: 'bold',
                                backgroundColor: '#00398f',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: darken('#00398f', 0.1),
                                    color: 'white',
                                },
                            }}
                        >
                            <img src={'/images/playbuilder/zigzagLight.svg'} alt="Zigzag Line" style={{ width: '40px', height: 'auto' }} />
                            {t('ActionButtons02')}
                        </Button>
                    </Grid>

                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            size={state.portrait ? 'small' : 'medium'}
                            fullWidth
                            onClick={() => createLine('dashed')}
                            sx={{
                                textTransform: 'none',
                                fontWeight: 'bold',
                                backgroundColor: '#00398f',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: darken('#00398f', 0.1),
                                    color: 'white',
                                },
                            }}
                        >
                            <img src={'/images/playbuilder/dashedLight.svg'} alt="Dashed Line" style={{ width: '40px', height: 'auto' }} />
                            {t('ActionButtons03')}
                        </Button>
                    </Grid>

                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            size={state.portrait ? 'small' : 'medium'}
                            fullWidth
                            onClick={() => createLine('bar')}
                            sx={{
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 'bold',
                                backgroundColor: '#00398f',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: darken('#00398f', 0.1),
                                    color: 'white',
                                },
                            }}
                        >
                            <Box mr={1}>
                                <img
                                    src={'/images/playbuilder/screenLight.svg'}
                                    alt="Bar Line"
                                    style={{ width: '40px', height: 'auto' }}
                                />
                            </Box>
                            {t('ActionButtons04')}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={state.portrait && hasConnectedLines ? 8 : 12}>
                        <Button
                            variant="outlined"
                            fullWidth
                            sx={{
                                // display: selectedPlayer ? 'flex' : 'none',
                                marginTop: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                textTransform: 'none',
                                borderRadius: '5px',
                                color: '#DD620f',
                                backgroundColor: 'white',
                                border: `1px solid #DD620f`,
                                fontWeight: 'bold',
                                '&:hover': {
                                    backgroundColor: '#f9f9f9',
                                    border: `1px solid #DD620f`,
                                },
                            }}
                            onClick={handleToggleBall}
                        >
                            <SportsBasketballRoundedIcon style={{ marginRight: '8px' }} />
                            {selectedPlayer?.hasBall ? t('ActionButtons06') : t('ActionButtons05')}
                        </Button>
                    </Grid>
                    <Grid item display={hasConnectedLines ? 'block' : 'none'} xs={state.portrait ? 4 : 12}>
                        <Button
                            variant="outlined"
                            color="inherit"
                            fullWidth
                            sx={{
                                display: 'flex',                // Asegura el uso de flexbox
                                alignItems: 'center',           // Centra verticalmente los elementos
                                justifyContent: 'center',       // Centra horizontalmente los elementos
                                textTransform: 'none',
                                borderRadius: '5px',
                                marginTop: state.portrait ? 1 : 0,
                                borderColor: '#b0bec5',
                                color: '#b0bec5',
                                '&:hover': {
                                    borderColor: darken('#b0bec5', 0.2),
                                    backgroundColor: 'rgba(176, 190, 197, 0.04)',
                                },
                            }}
                            onClick={handleDeleteLine}
                        >
                            <DeleteIcon style={{ marginRight: '8px' }} />
                            {state.portrait ? t('ActionButtons08') : t('ActionButtons07')}
                        </Button>
                    </Grid>
                </Grid>
            </div >
        </>
    );
}