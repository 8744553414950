import { Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Typography } from "@mui/material";
import ICBDialogTitle from "./ICBDialogTitle";
import { useTranslation } from "react-i18next";
import log from "../misc/Logger";
import { Fragment, useContext, useEffect, useState } from "react";
import { JSONAPITypeId, OperationMode, PlaybookURLAndCredentials, TypeContext } from "../misc/Types";
import { useNavigate } from "react-router-dom";
import { Context } from "../App";
import { getActionSetConfirm, getAllContentEntities, getDD, getPlaybookSharable, icbControllerGenerel02 } from "../misc/Functions";
import { BACKEND, DRUPALENTITYINIT } from "../misc/Constants";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SnackbarMessages from "./SnackbarMessages";
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import { useTheme } from '@mui/material/styles';
export interface PropsDialogPlaybook {
    open: boolean,
    onClose: () => any;
}

// show this dialog when a user selects a playbook from the playbook list.
// there are options to edit, delete or get sharable link for playbook
export default function DialogPlaybook(props: PropsDialogPlaybook) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    log.debug('DialogPlaybook');

    const [playbookURLAndCredentials, setPlaybookURLAndCredentials] = useState<PlaybookURLAndCredentials>()
    const [openSnackbarMessage00, setOpenSnackbarMessage00] = useState(false)
    const [openSnackbarMessage01, setOpenSnackbarMessage01] = useState(false)
    useEffect(() => {
        setPlaybookURLAndCredentials(JSON.parse(state.curPlaybook.attributes.field_url_and_credentials || null))
    }, [state.curPlaybook])

    async function handleDeletePlaybook() {
        dispatch(getActionSetConfirm(t('DialogPlaybook00'), 'ok', async () => {
            // First, delete sharable link to playbook
            if (playbookURLAndCredentials)
                await getDD(state, dispatch, `${BACKEND}/icb-playbook/remove/${state.curPlaybook.attributes.drupal_internal__nid}`)
            // Then, delete playbook
            const resp = await icbControllerGenerel02(state, {
                opr: "delete_node",
                nid: state.curPlaybook.id,
            })
            if (resp.ok) {
                dispatch({ type: 'delContentEntity', contentType: 'node--playbook', id: state.curPlaybook.id })
                dispatch({ type: 'setPlaybook', playbook: DRUPALENTITYINIT })
                props.onClose()
            } else
                dispatch(getActionSetConfirm(resp.error));
        }));
    }

    async function handleDeleteSharableLink() {
        getDD(state, dispatch, `${BACKEND}/icb-playbook/remove/${state.curPlaybook.attributes.drupal_internal__nid}`)
            .then(() => {
                getAllContentEntities(state, dispatch, 'node--playbook', `?filter[nid]=${state.curPlaybook.attributes.drupal_internal__nid}`, false) // fetch updated playbook
            })
    }

    function getPlaybookLinkWithCredentials(): string {
        let urlWithCredentials = ''
        if (playbookURLAndCredentials) {
            // Build URL with htaccess userid and password for easy access
            const BACKENDSplit = BACKEND.split('https://')
            urlWithCredentials = `https://${playbookURLAndCredentials.htaccess_uid}:${playbookURLAndCredentials.htaccess_pwd}@${BACKENDSplit[1]}/${playbookURLAndCredentials.url}`
        }
        return urlWithCredentials
    }

    return (
        <Fragment>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                PaperProps={{
                    style: {
                        width: '95%',
                        maxWidth: '500px',
                    },
                }}
            >
                <ICBDialogTitle onClose={props.onClose} title={`Playbook: ${state.curPlaybook.attributes.title || t('DialogPlaybook')}`} />

                <DialogContent>
                    {/* link created */}
                    {
                        getPlaybookLinkWithCredentials() &&
                        <Fragment>
                            {/* Link with credentials */}
                            {/* <Link
                            href={getPlaybookLinkWithCredentials()}
                            target="_blank"
                            rel="noopener"
                        >
                            Link (opens in a new tab)
                        </Link> */}

                            {/* URL */}
                            <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>
                                {t('Link')}
                            </Typography>

                            {/* Copy link */}
                            <Box
                                display="flex"
                                border="1px solid #ccc"
                                borderRadius="4px"
                                mb={1}
                                style={{ padding: '5px' }}
                            >
                                <Typography style={{ flexGrow: 1, wordBreak: 'break-all', fontSize: 12 }}>
                                    {`${BACKEND}/${playbookURLAndCredentials?.url}` || t('DialogPlaybook01')}
                                </Typography>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        const url = `${BACKEND}/${playbookURLAndCredentials?.url}` || t('DialogPlaybook01');
                                        navigator.clipboard.writeText(url)
                                            .then(() => {
                                                log.info('URL copied successfully');
                                                // Activate Snackbar for 2 seconds. Autohide in parent would be inconsistent when double clicking fast
                                                setOpenSnackbarMessage00(true); setTimeout(() => { setOpenSnackbarMessage00(false); }, 2500)

                                            })
                                            .catch((error) => {
                                                log.error(`Error copying URL to clipboard: ${error}`);
                                            });
                                    }}
                                >
                                    <ContentCopyIcon />
                                </IconButton>
                            </Box>

                            {/* Username for the playbook */}
                            <Typography style={{ fontWeight: 'bold', marginTop: 1, fontSize: 12 }}>
                                {t('AccountCaptcha00')}
                            </Typography>
                            <Box
                                display="flex"
                                border="1px solid #ccc"
                                borderRadius="4px"
                                mb={1}
                                style={{ padding: '5px' }}
                            >
                                <Typography style={{ flexGrow: 1, wordBreak: 'break-all', fontSize: 12 }}>
                                    {/* this is static text --- user is always the same "icb" */}
                                    icb
                                </Typography>
                            </Box>

                            {/* Password */}
                            <Typography style={{ fontWeight: 'bold', marginTop: 1, fontSize: 12 }}>
                                {t('AccountCaptcha04')}
                            </Typography>
                            <Box
                                display="flex"
                                border="1px solid #ccc"
                                borderRadius="4px"
                                mb={1}
                                style={{ padding: '5px' }}
                            >
                                <Typography style={{ flexGrow: 1, wordBreak: 'break-all', fontSize: 12 }}>
                                    {`${playbookURLAndCredentials?.htaccess_pwd}` || t('DialogPlaybook01')}
                                </Typography>
                            </Box>

                            {/* Copy link and password */}
                            <Button
                                color="primary"
                                size="small"
                                onClick={() => {
                                    const link = `${BACKEND}/${playbookURLAndCredentials?.url}` || t('DialogPlaybook01');
                                    const user = 'icb';
                                    const password = `${playbookURLAndCredentials?.htaccess_pwd}` || t('DialogPlaybook01');
                                    const textToCopy = `${t('DialogPlaybook02')}: ${link}\n\n${t('AccountCaptcha00')}: ${user}\n${t('AccountCaptcha04')}: ${password}`;
                                    navigator.clipboard.writeText(textToCopy)
                                        .then(() => {
                                            log.info('Link and password copied to clipboard');
                                            setOpenSnackbarMessage01(true); setTimeout(() => { setOpenSnackbarMessage01(false); }, 2500)
                                        })
                                        .catch((error) => {
                                            log.error(`Error copying link and password to clipboard: ${error}`);
                                        });
                                }}
                                sx={{ textTransform: 'none', fontSize: 12, fontWeight: 'bold' }}
                            >
                                {t('DialogPlaybook03')}
                            </Button>

                            {/* Unpublish playbook link */}
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <Button
                                size="small"
                                sx={{ textTransform: 'none' }}
                                variant="outlined"
                                color="secondary"
                                onClick={handleDeleteSharableLink}
                            >
                                {t('DialogPlaybook04')}
                            </Button>
                        </Fragment>
                    }

                    {/* Create sharable link - no link created */}
                    {
                        !getPlaybookLinkWithCredentials() &&
                        <Fragment>
                            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                                {t('DialogPlaybook05')}
                            </Typography>
                            <Typography style={{ fontSize: 14 }}>
                                {t('DialogPlaybook06')}
                            </Typography>
                            <Button sx={{ fontWeight: 'bold', marginTop: 1, textTransform: 'none' }}
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    // Check access to all plays in playbook
                                    if (state.curPlaybook.relationships.field_plays.data.find((x: JSONAPITypeId) => !state.allPlays.find(y => y.id === x.id))) {
                                        dispatch(getActionSetConfirm(t('CreatePlaybook12')));
                                        return
                                    }

                                    getPlaybookSharable(state, state.curPlaybook)
                                        .then(playbook => {
                                            getDD(state, dispatch, `${BACKEND}/icb-playbook/create/${state.curPlaybook.attributes.drupal_internal__nid}`, 'POST', playbook)
                                                .then(() => {
                                                    getAllContentEntities(state, dispatch, 'node--playbook', `?filter[nid]=${state.curPlaybook.attributes.drupal_internal__nid}`, false) // fetch updated playbook
                                                })
                                        })
                                }}
                            >
                                {t('DialogPlaybook07')}
                            </Button>
                        </Fragment>
                    }


                    <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                        {/* Edit playbook */}
                        <Button
                            size="small"
                            sx={{ textTransform: 'none' }}
                            onClick={() => {
                                navigate('/playbook');
                                dispatch({ type: 'setOperationMode', operationMode: OperationMode.play });
                                dispatch({ type: 'showPracticeProgram', show: true });
                                // remove id so subsequent save creates a new playbook
                                dispatch({
                                    type: 'setPlaybook', playbook: {
                                        ...state.curPlaybook,
                                        id: null,
                                    }
                                });
                                navigate('/icbplays');
                                props.onClose()
                            }}
                        >
                            {t('DialogPlaybook08')}
                        </Button>

                        {/* Delete playbook */}
                        <Button
                            size="small"
                            sx={{ textTransform: 'none', color: theme.palette.text.secondary }}
                            onClick={handleDeletePlaybook}
                        >
                            {t('DialogPlaybook09')}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog >
            <SnackbarMessages message={t('DialogPlaybook10')} open={openSnackbarMessage00} icon={<PlaylistAddCheckCircleRoundedIcon fontSize="small" sx={{ color: '#2e7d36' }} />} color={'#2e7d36'} />
            <SnackbarMessages message={t('DialogPlaybook11')} open={openSnackbarMessage01} icon={<PlaylistAddCheckCircleRoundedIcon fontSize="small" sx={{ color: '#2e7d36' }} />} color={'#2e7d36'} />

        </Fragment>
    )
}   