export function BasketballFullCourt(props: any) {
    // https://commons.wikimedia.org/wiki/File:Basketball_court_fiba.svg
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="30 120 850 305"
            {...props}
        >
            <g transform="rotate(90, 454.96, 454.96)">

                <svg width="20000" height="3000" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <pattern
                            id="woodPattern"
                            width="100"
                            height="300"
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x="0" y="0" width="11" height="100" fill="#e4c49c" />
                            <rect x="11" y="0" width="11" height="100" fill="#e4cc9c" />
                            <rect x="22" y="0" width="11" height="100" fill="#e4c494" />
                            <rect x="33" y="0" width="11" height="100" fill="#dcbc94" />
                            <rect x="44" y="0" width="11" height="100" fill="#dcc49c" />
                            <rect x="55" y="0" width="11" height="100" fill="#e4cca4" />
                            <rect x="66" y="0" width="11" height="100" fill="#e4c8a0" />
                            <rect x="70" y="0" width="11" height="100" fill="#e4c49c" />
                            <rect x="81" y="0" width="11" height="100" fill="#e4cc9c" />
                            <rect x="92" y="0" width="11" height="100" fill="#e4c494" />

                            <rect x="0" y="70" width="11" height="100" fill="#e4c49c" />
                            <rect x="11" y="70" width="11" height="100" fill="#e4cc9c" />
                            <rect x="22" y="70" width="11" height="100" fill="#e4c494" />
                            <rect x="33" y="70" width="11" height="100" fill="#dcbc94" />
                            <rect x="44" y="70" width="11" height="100" fill="#dcc49c" />
                            <rect x="55" y="70" width="11" height="100" fill="#e4cca4" />
                            <rect x="66" y="70" width="11" height="100" fill="#e4c8a0" />
                            <rect x="70" y="70" width="11" height="100" fill="#e4c49c" />
                            <rect x="81" y="70" width="11" height="100" fill="#e4cc9c" />
                            <rect x="92" y="70" width="11" height="100" fill="#e4c494" />

                            <rect x="0" y="140" width="11" height="100" fill="#e4c8a0" />
                            <rect x="11" y="140" width="11" height="100" fill="#e4c49c" />
                            <rect x="22" y="140" width="11" height="100" fill="#e4cc9c" />
                            <rect x="33" y="140" width="11" height="100" fill="#e4c494" />
                            <rect x="44" y="140" width="11" height="100" fill="#dcbc94" />
                            <rect x="55" y="140" width="11" height="100" fill="#dcc49c" />
                            <rect x="66" y="140" width="11" height="100" fill="#e4cca4" />
                            <rect x="70" y="140" width="11" height="100" fill="#e4c8a0" />
                            <rect x="81" y="140" width="11" height="100" fill="#e4c49c" />
                            <rect x="92" y="140" width="11" height="100" fill="#e4cc9c" />

                            <rect x="0" y="200" width="11" height="100" fill="#e4c8a0" />
                            <rect x="11" y="200" width="11" height="100" fill="#e4c49c" />
                            <rect x="22" y="200" width="11" height="100" fill="#e4cc9c" />
                            <rect x="33" y="200" width="11" height="100" fill="#e4c494" />
                            <rect x="44" y="200" width="11" height="100" fill="#dcbc94" />
                            <rect x="55" y="200" width="11" height="100" fill="#dcc49c" />
                            <rect x="66" y="200" width="11" height="100" fill="#e4cca4" />
                            <rect x="70" y="200" width="11" height="100" fill="#e4c8a0" />
                            <rect x="81" y="200" width="11" height="100" fill="#e4c49c" />
                            <rect x="92" y="200" width="11" height="100" fill="#e4cc9c" />

                        </pattern>
                    </defs>
                    <rect width="100%" height="100%" fill="url(#woodPattern)" />
                </svg>

                <g>
                    <g id="Floor_Markings">
                        <rect x="28.35" y="28.35" fill="none" width="484.72" height="853.23" />
                        <path fill="url(#woodPattern)" d="M484.72,56.69v796.54H56.69V56.69H484.72 M541.42,0H0v909.92h541.42V0L541.42,0z" />
                    </g>
                    <g id="Court_Markings">
                        <g id="Boundary_Line_and_Centre_Line">
                            <g id="Boundary_Line">
                                <rect x="56.74" y="58.11" fill="#FFFFFF" width="3" height="794.36" />
                                <rect x="483.31" y="58.11" fill="#FFFFFF" width="3" height="794.36" />
                                <rect x="56.74" y="56.69" fill="#FFFFFF" width="427.99" height="3" />
                                <rect x="56.74" y="852.48" fill="#FFFFFF" width="427.99" height="3" />
                            </g>
                            <rect x="52.53" y="454.59" fill="#FFFFFF" width="436.41" height="3" />
                            <path id="Centre_Circle_00000070833358215776187290000005064515555768438672_" fill="#FFFFFF" d="M270.73,405.65
				c27.35,0,49.6,22.27,49.6,49.65s-22.25,49.65-49.6,49.65c-27.35,0-49.6-22.27-49.6-49.65S243.38,405.65,270.73,405.65
				 M270.73,404.23c-28.18,0-51.02,22.86-51.02,51.07s22.84,51.07,51.02,51.07s51.02-22.86,51.02-51.07S298.91,404.23,270.73,404.23
				L270.73,404.23z"/>
                        </g>
                        <g id="_x33__Point_Line">
                            <rect x="83.7" y="767.3" fill="#FFFFFF" width="2.5" height="85.18" />
                            <rect x="456.38" y="767.43" fill="#FFFFFF" width="1.42" height="85.04" />
                            <path fill="#FFFFFF" d="M457.8,767.44l-1.41,0.1h-0.01c-2.47-11.52-6.04-22.79-10.67-33.74c-9.56-22.63-23.26-42.96-40.7-60.42
				c-17.44-17.45-37.75-31.16-60.36-40.73c-23.41-9.91-48.28-14.93-73.91-14.93c-25.64,0-50.51,5.02-73.92,14.93
				c-22.61,9.58-42.92,23.28-60.36,40.73c-17.44,17.46-31.13,37.79-40.7,60.42c-4.61,10.91-8.16,22.13-10.63,33.6h-0.01l-1.41-0.09
				c18.56-86.32,95.24-151.02,187.03-151.02C362.57,616.29,439.29,681.06,457.8,767.44z"/>
                        </g>
                        <g id="Key" fill="#FFFFFF">
                            <g id="Key_Base">
                                <path fill="#FFFFFF" d="M338.76,689.35v163.13H202.71V689.35H338.76 M340.18,687.93H201.29v165.97h138.89V687.93L340.18,687.93z" />
                                <path fill="#FFFFFF" d="M321.74,687.93h-1.41c-0.37-27.05-22.48-48.94-49.6-48.94c-27.12,0-49.23,21.89-49.6,48.94h-1.41
					c0.37-27.87,23.06-50.36,51.01-50.36C298.68,637.57,321.37,660.05,321.74,687.93z"/>
                            </g>
                            <g id="Rebound_Places">
                                <rect x="340.18" y="801.41" fill="#FFFFFF" width="2.83" height="1.42" />
                                <rect x="340.18" y="765.94" fill="#FFFFFF" width="2.83" height="11.35" />
                                <rect x="340.18" y="740.41" fill="#FFFFFF" width="2.83" height="1.42" />
                                <rect x="340.18" y="714.88" fill="#FFFFFF" width="2.83" height="1.42" />
                                <rect x="198.46" y="801.41" fill="#FFFFFF" width="2.83" height="1.42" />
                                <rect x="198.46" y="765.95" fill="#FFFFFF" width="2.83" height="11.35" />
                                <rect x="198.46" y="740.41" fill="#FFFFFF" width="2.83" height="1.42" />
                                <rect x="198.46" y="714.88" fill="#FFFFFF" width="2.83" height="1.42" />
                            </g>
                        </g>
                        <g id="No-Charge_Circle">
                            <rect x="306.15" y="807.79" fill="#FFFFFF" width="1.42" height="10.64" />
                            <path fill="#FFFFFF" d="M307.57,807.79h-1.42c-0.37-19.23-16.11-34.76-35.42-34.76c-19.31,0-35.05,15.53-35.42,34.76h-1.42
				c0.37-20.05,16.72-36.18,36.84-36.18C290.85,771.61,307.2,787.75,307.57,807.79z"/>
                            <rect x="233.9" y="807.79" fill="#FFFFFF" width="1.42" height="10.64" />
                        </g>
                        <rect id="Throw-in_Line" x="52.48" y="614.87" fill="#FFFFFF" width="4.25" height="1.42" />
                        {/* <image href="/images/playbuilder/icbWhite.svg" x="-8" y="382" width="50" height="50" transform="rotate(270 100 410)" /> */}
                        {/* <image href="/images/playbuilder/icbWhite.svg" x="63" y="730" width="50" height="50" transform="rotate(270 100 410)" /> */}
                        <g id="Team_Bench_Area">

                            <rect id="Team_Bench_00000088106750679376283670000012279022595219760517_" x="484.73" y="597.85" fill="#FFFFFF" width="56.69" height="3" />
                            <rect id="Team_Bench" x="484.73" y="852.48" fill="#FFFFFF" width="56.69" height="3" />
                        </g>
                        <g id="Half_Markings">
                            <g id="_x33__Point_Line_00000126295001213732848230000002648695590174514839_">
                                <rect x="83.6" y="58.11" fill="#FFFFFF" width="2.5" height="85.18" />
                                <rect x="456.28" y="58.11" fill="#FFFFFF" width="2.5" height="85.04" />
                                <path
                                    fill="#FFFFFF"
                                    stroke="#FFFFFF"
                                    transform="translate(0.5,0)"
                                    d="M457.7,143.15l-1.41-0.1h-0.01c-2.47,11.52-6.04,22.79-10.67,33.74c-9.56,22.63-23.26,42.96-40.7,60.42
    c-17.44,17.45-37.75,31.16-60.36,40.73c-23.41,9.91-48.28,14.93-73.91,14.93c-25.64,0-50.51-5.02-73.92-14.93
    c-22.61-9.58-42.92-23.28-60.36-40.73c-17.44-17.46-31.13-37.79-40.7-60.42c-4.61-10.91-8.16-22.13-10.63-33.6h-0.01l-1.41,0.09
    c18.56,86.32,95.24,151.02,187.03,151.02C362.46,294.29,439.19,229.53,457.7,143.15z"/>

                            </g>
                            <g id="Key_00000056419209107017033040000005191718291191303861_">
                                <g id="Key_Base_00000045580905087958776380000009480438004150356358_">
                                    <path fill="#FFFFFF" d="M338.66,58.11v163.13H202.6V58.11H338.66 M340.07,56.69H201.19v165.97h138.89V56.69L340.07,56.69z" />
                                    <path fill="#FFFFFF" d="M321.64,222.66h-1.41c-0.37,27.05-22.48,48.94-49.6,48.94c-27.12,0-49.23-21.89-49.6-48.94h-1.41
						c0.37,27.87,23.06,50.36,51.01,50.36C298.58,273.02,321.27,250.53,321.64,222.66z"/>
                                </g>
                                <g id="Rebound_Places_00000133531023428113855130000003839947234106507163_">
                                    <rect x="340.07" y="107.76" fill="#FFFFFF" width="2.83" height="1.42" />
                                    <rect x="340.07" y="133.29" fill="#FFFFFF" width="2.83" height="11.35" />
                                    <rect x="340.07" y="168.76" fill="#FFFFFF" width="2.83" height="1.42" />
                                    <rect x="340.07" y="194.29" fill="#FFFFFF" width="2.83" height="1.42" />
                                    <rect x="198.35" y="107.76" fill="#FFFFFF" width="2.83" height="1.42" />
                                    <rect x="198.35" y="133.29" fill="#FFFFFF" width="2.83" height="11.35" />
                                    <rect x="198.35" y="168.76" fill="#FFFFFF" width="2.83" height="1.42" />
                                    <rect x="198.35" y="194.29" fill="#FFFFFF" width="2.83" height="1.42" />
                                </g>
                            </g>
                            <g id="No-Charge_Circle_00000119105268069419404570000008776237732058513808_">
                                <rect x="306.05" y="92.16" fill="#FFFFFF" width="1.42" height="10.64" />
                                <path fill="#FFFFFF" d="M307.47,102.79h-1.42c-0.37,19.23-16.11,34.76-35.42,34.76s-35.05-15.53-35.42-34.76h-1.42
					c0.37,20.05,16.72,36.18,36.84,36.18S307.1,122.84,307.47,102.79z"/>
                                <rect x="233.79" y="92.16" fill="#FFFFFF" width="1.42" height="10.64" />
                            </g>

                            <rect id="Throw-in_Line_00000155855732892319826830000001737842726948715944_" x="52.38" y="294.29" fill="#FFFFFF" width="4.25" height="2.5" />
                            <g id="Team_Bench_Area_00000074407288892148501920000002787382391698087838_">

                                <rect id="Team_Bench_00000085214562878665392920000002217376610200284599_" x="484.62" y="311.32" fill="#FFFFFF" width="56.69" height="2.5" />

                                <rect id="Team_Bench_00000018940718475814950360000009421589212662107563_" x="484.62" y="56.69" fill="#FFFFFF" width="56.69" height="2.5" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>

            {/* ICB WHITE LOGO UPPER LEFT */}
            <svg x="392" y="72" width="50" height="50" transform="rotate(270 100 410)" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 1409.00 1487.00">
                <g strokeWidth="2.00" fill="none" strokeLinecap="butt">
                </g>
                <path fill="white" d="
  M 844.33 1433.31
  Q 839.61 1439.89 832.01 1440.58
  Q 825.04 1441.20 812.63 1441.07
  Q 808.22 1424.63 800.85 1410.03
  A 2.24 2.23 68.7 0 0 798.26 1408.89
  L 781.05 1413.65
  Q 780.54 1413.79 780.08 1413.56
  Q 777.64 1412.34 776.79 1409.75
  Q 764.31 1371.68 738.90 1341.36
  Q 730.66 1331.54 718.72 1322.75
  Q 708.63 1315.32 703.02 1312.19
  Q 677.86 1298.14 648.75 1291.73
  C 646.78 1291.30 645.61 1290.16 645.25 1288.16
  Q 642.27 1271.88 638.98 1260.26
  Q 624.84 1210.28 602.67 1140.73
  Q 599.63 1131.18 596.70 1121.63
  C 582.44 1075.22 568.08 1031.42 552.19 987.62
  A 3.85 3.79 -82.3 0 0 550.86 985.85
  C 543.59 980.55 537.69 976.49 529.69 969.81
  Q 511.85 954.91 491.07 933.95
  C 476.78 919.53 463.91 903.66 451.47 887.51
  Q 391.54 809.68 364.52 714.53
  Q 362.73 708.22 357.97 689.45
  Q 355.48 679.63 353.95 669.86
  Q 351.26 652.71 349.14 637.72
  C 347.03 622.85 345.72 607.69 345.23 592.36
  Q 342.98 522.32 360.76 453.73
  C 372.23 409.51 389.38 366.59 412.50 327.19
  C 417.52 318.63 422.50 310.26 427.94 302.06
  Q 458.40 256.10 495.49 219.05
  Q 508.17 206.39 526.09 190.93
  Q 533.57 184.49 541.64 178.34
  C 571.85 155.36 604.80 135.86 638.94 119.73
  Q 651.62 113.74 675.61 104.25
  Q 685.14 100.47 694.44 97.49
  Q 742.69 82.02 791.53 74.92
  Q 805.92 72.83 831.32 70.96
  Q 873.21 67.89 912.75 72.33
  Q 922.87 73.47 932.81 74.71
  Q 942.36 75.90 952.49 77.91
  Q 969.25 81.24 984.72 84.52
  Q 989.37 85.51 994.74 87.08
  Q 1015.34 93.13 1033.88 99.68
  Q 1045.88 103.91 1061.60 110.56
  Q 1116.94 133.97 1164.98 170.12
  Q 1188.30 187.66 1208.98 206.80
  Q 1261.38 255.30 1297.00 314.60
  Q 1306.60 330.58 1315.64 347.33
  C 1321.74 358.61 1326.80 370.46 1331.99 382.17
  Q 1338.57 397.03 1346.95 420.55
  Q 1350.60 430.80 1353.13 439.80
  Q 1368.06 492.96 1371.39 540.64
  Q 1374.13 579.99 1370.78 620.68
  Q 1369.24 639.31 1364.34 665.97
  C 1348.88 749.99 1311.84 828.94 1258.75 895.88
  Q 1252.31 904.00 1246.18 910.98
  Q 1225.43 934.60 1202.14 957.52
  C 1193.52 966.01 1183.31 973.26 1173.49 980.44
  Q 1144.49 1001.62 1116.83 1017.65
  Q 1075.29 1041.73 1030.31 1057.05
  Q 1024.74 1058.95 1019.91 1062.90
  Q 973.77 1100.71 914.63 1125.93
  Q 888.20 1137.20 879.89 1140.14
  Q 860.73 1146.91 844.70 1153.70
  A 1.75 1.74 -18.9 0 1 842.34 1152.55
  Q 839.41 1141.73 834.95 1131.19
  Q 829.10 1117.38 822.23 1104.52
  Q 821.65 1103.43 822.86 1103.18
  C 835.24 1100.70 847.32 1096.84 859.59 1093.87
  Q 863.07 1093.03 865.39 1090.95
  Q 867.06 1089.47 864.84 1089.67
  Q 846.07 1091.35 827.01 1088.95
  Q 811.53 1087.01 794.23 1085.23
  A 1.51 1.45 -55.9 0 0 793.34 1085.42
  Q 792.03 1086.15 792.55 1087.63
  C 799.51 1107.21 808.72 1125.74 816.44 1145.01
  Q 820.82 1155.94 826.17 1166.04
  Q 835.08 1182.88 844.03 1198.97
  C 848.75 1207.45 850.82 1214.91 848.08 1224.00
  C 844.32 1236.48 839.63 1249.52 836.26 1263.21
  C 833.48 1274.51 831.60 1287.04 830.32 1298.31
  Q 828.27 1316.25 831.79 1334.41
  Q 834.79 1349.96 841.96 1367.00
  C 845.86 1376.29 848.35 1386.02 844.29 1395.77
  Q 843.24 1398.31 840.60 1399.79
  Q 836.56 1402.06 831.92 1403.98
  A 0.71 0.70 -23.4 0 0 831.54 1404.92
  L 844.33 1433.31
  Z
  M 970.92 146.55
  C 927.02 134.76 880.72 130.74 835.94 133.61
  Q 819.27 134.68 799.47 136.75
  A 0.78 0.77 83.8 0 0 798.78 137.61
  C 798.92 138.78 800.23 139.43 801.28 139.52
  Q 810.52 140.32 813.00 140.86
  Q 833.83 145.46 854.51 151.12
  Q 876.07 157.03 894.90 165.42
  Q 906.14 170.43 913.55 175.24
  C 925.15 182.75 936.84 189.10 948.72 195.71
  C 961.56 202.86 976.97 210.23 987.90 215.88
  Q 1005.49 224.99 1020.76 233.33
  Q 1030.28 238.54 1037.21 240.75
  Q 1045.78 243.49 1055.07 244.53
  C 1061.86 245.29 1070.19 243.62 1075.46 238.84
  C 1082.43 232.51 1085.06 221.94 1084.45 212.50
  C 1083.43 196.92 1073.52 188.08 1060.63 181.38
  Q 1045.75 173.65 1032.06 167.57
  C 1012.19 158.74 991.85 152.18 970.92 146.55
  Z
  M 555.22 255.24
  Q 555.77 256.14 556.75 255.74
  Q 570.93 250.01 581.22 246.32
  C 587.73 243.98 595.47 242.85 602.61 240.37
  Q 609.95 237.82 617.32 235.91
  Q 618.63 235.57 620.93 234.36
  Q 623.46 233.03 625.05 233.99
  Q 610.41 241.37 596.06 248.33
  Q 590.29 251.13 563.41 267.02
  C 558.07 270.18 553.94 274.02 548.99 277.54
  Q 543.44 281.51 538.42 286.01
  Q 520.82 301.78 512.10 311.10
  Q 499.41 324.66 487.82 339.80
  Q 483.70 345.18 480.69 350.96
  Q 472.45 361.74 463.20 377.43
  Q 447.73 403.72 439.85 420.53
  Q 439.14 422.04 439.30 423.72
  A 0.66 0.65 65.2 0 0 440.41 424.13
  Q 474.01 391.30 516.84 371.58
  Q 539.08 361.34 559.23 354.80
  C 599.18 341.82 642.93 333.10 688.12 327.69
  Q 712.36 324.79 739.69 324.47
  Q 768.05 324.12 789.01 325.05
  Q 812.04 326.08 835.87 329.46
  Q 869.54 334.24 900.14 341.88
  Q 925.79 348.28 954.43 360.29
  C 955.43 360.71 956.77 360.65 957.53 359.78
  Q 965.13 350.94 972.32 339.80
  Q 990.93 310.95 983.72 277.09
  Q 981.90 268.56 976.29 262.65
  Q 972.72 258.89 963.91 254.22
  Q 935.23 238.97 902.29 220.74
  Q 890.65 214.30 884.63 211.39
  Q 869.41 204.01 854.85 198.88
  Q 832.19 190.90 806.98 184.11
  Q 797.53 181.57 787.97 179.60
  Q 771.91 176.28 758.01 174.39
  Q 757.29 174.29 757.12 174.90
  Q 754.79 173.46 753.50 173.28
  Q 723.07 169.00 692.99 168.98
  Q 691.07 168.98 689.38 169.56
  Q 679.38 173.00 669.78 177.40
  Q 643.56 189.42 621.39 203.01
  Q 610.37 209.77 596.24 219.22
  C 583.12 228.00 571.91 237.46 560.12 247.48
  Q 556.92 250.20 554.48 253.89
  A 1.13 1.06 46.3 0 0 554.49 255.07
  Q 554.62 255.29 555.22 255.24
  Z
  M 1154.15 246.49
  C 1146.87 240.00 1138.14 233.41 1129.81 227.39
  Q 1128.77 226.63 1127.55 226.75
  Q 1126.24 226.88 1126.08 228.18
  Q 1124.75 239.06 1120.59 249.67
  C 1116.18 260.90 1108.95 269.34 1098.73 275.29
  C 1094.28 277.88 1088.44 279.89 1083.20 281.81
  A 1.04 1.04 0.0 0 0 1082.90 283.59
  Q 1119.96 314.01 1152.46 348.48
  A 2.38 2.37 52.8 0 0 1155.42 348.89
  Q 1188.12 329.29 1225.60 329.94
  A 1.04 1.04 0.0 0 0 1226.54 328.41
  Q 1223.00 321.77 1218.23 315.59
  Q 1188.72 277.32 1154.15 246.49
  Z
  M 1109.14 398.18
  C 1115.50 390.86 1122.25 383.83 1128.02 375.97
  A 0.84 0.83 43.4 0 0 1127.99 374.95
  Q 1119.78 364.79 1111.82 357.36
  Q 1093.53 340.29 1074.63 324.66
  Q 1066.35 317.81 1058.73 312.10
  Q 1042.99 300.31 1028.41 289.39
  Q 1027.23 288.51 1026.20 289.61
  A 1.70 1.68 -24.7 0 0 1025.74 290.84
  Q 1027.44 326.89 1006.71 359.50
  Q 999.91 370.20 994.10 377.40
  Q 993.40 378.27 993.19 379.33
  Q 992.92 380.74 994.11 381.44
  Q 1022.86 398.25 1048.44 420.11
  Q 1061.18 430.99 1074.19 442.05
  Q 1075.77 443.39 1076.92 441.67
  Q 1087.46 425.89 1098.89 410.82
  Q 1103.91 404.20 1109.14 398.18
  Z
  M 725.76 364.71
  Q 725.41 364.42 724.99 364.41
  Q 716.41 364.13 708.98 365.21
  Q 685.34 368.65 662.18 372.70
  Q 650.82 374.69 640.05 377.37
  Q 630.91 379.64 621.39 381.98
  Q 599.15 387.42 577.53 395.01
  Q 544.46 406.62 513.86 425.12
  Q 513.09 425.58 512.51 426.25
  Q 511.97 426.11 511.46 426.45
  Q 491.80 439.81 475.03 454.95
  Q 470.12 459.38 466.62 463.68
  C 460.11 471.66 452.78 479.34 447.24 487.53
  Q 439.44 499.05 432.26 512.35
  C 426.76 522.54 422.76 534.06 419.65 545.19
  Q 417.48 552.99 416.28 562.42
  C 414.19 578.80 413.66 593.60 414.72 610.20
  C 415.92 628.98 417.47 645.80 421.42 664.40
  Q 425.23 682.39 429.69 699.08
  C 433.18 712.17 438.65 725.43 443.46 738.16
  A 2.58 2.58 0.0 0 0 446.89 739.63
  Q 457.15 735.25 467.55 731.67
  C 471.55 730.29 475.62 728.00 479.48 725.90
  Q 490.40 720.00 502.54 717.01
  C 505.94 716.17 509.72 716.54 513.24 716.46
  Q 515.00 716.42 516.53 715.39
  Q 523.94 710.40 530.03 705.58
  C 531.87 704.12 534.90 702.61 537.13 701.98
  Q 553.00 697.45 561.90 693.73
  Q 567.18 691.52 572.71 689.06
  C 580.57 685.56 589.41 687.25 596.75 692.40
  Q 604.09 697.55 608.65 705.25
  A 1.53 1.52 55.0 0 0 610.93 705.66
  Q 620.11 698.14 631.61 697.08
  C 636.16 696.67 641.68 696.26 646.08 697.16
  Q 656.37 699.25 662.67 708.56
  Q 669.84 719.17 672.54 729.02
  C 675.00 738.02 675.61 748.80 676.98 758.57
  A 1.96 1.96 0.0 0 1 674.34 760.68
  L 667.27 757.99
  A 1.00 0.99 -59.6 0 0 666.14 758.29
  Q 665.76 758.75 666.08 759.27
  C 669.27 764.59 672.01 770.04 674.20 775.80
  Q 684.97 804.10 698.31 837.96
  Q 729.99 918.38 761.40 999.65
  A 0.86 0.86 0.0 0 0 762.94 999.79
  C 766.47 994.01 769.70 988.13 773.77 982.77
  C 779.74 974.88 785.02 966.73 792.34 959.58
  Q 795.69 956.31 800.65 950.02
  Q 822.02 922.88 839.55 895.19
  C 863.98 856.58 884.50 813.12 904.90 767.63
  Q 921.09 731.54 936.85 696.21
  Q 967.84 626.76 997.64 570.55
  Q 1005.91 554.95 1017.24 534.35
  C 1026.74 517.08 1037.16 499.81 1047.27 483.43
  Q 1049.57 479.70 1052.94 476.39
  A 1.41 1.41 0.0 0 0 1053.01 474.47
  C 1042.61 462.51 1031.75 451.35 1019.66 441.39
  Q 1011.55 434.72 1002.04 427.43
  Q 987.90 416.59 969.70 407.44
  Q 967.74 406.45 966.31 407.89
  C 962.92 411.26 959.62 414.87 955.99 417.81
  Q 928.59 440.05 910.24 451.47
  Q 881.01 469.66 853.56 486.81
  C 845.44 491.88 835.34 497.02 827.07 502.08
  C 819.98 506.41 813.15 510.71 806.02 514.72
  Q 773.41 533.05 741.64 552.36
  Q 689.34 584.15 641.59 619.50
  C 633.47 625.51 626.12 632.13 618.51 638.31
  C 610.27 644.99 602.84 651.97 594.99 659.03
  Q 589.24 664.21 583.25 665.72
  Q 551.80 673.64 520.65 686.19
  C 517.36 687.52 513.86 689.09 510.92 690.93
  C 498.65 698.61 486.02 706.51 473.09 713.51
  A 1.16 1.16 0.0 0 1 471.40 712.27
  Q 471.94 709.42 473.58 707.08
  Q 480.53 697.16 487.93 688.10
  Q 490.41 685.06 493.32 682.06
  Q 548.87 624.93 614.13 579.79
  Q 621.91 574.41 630.64 568.76
  Q 644.89 559.55 659.16 550.53
  Q 677.20 539.12 704.47 523.13
  Q 713.44 517.87 722.08 513.04
  Q 774.88 483.56 827.38 453.97
  Q 854.99 438.42 887.10 417.69
  C 899.39 409.77 910.64 400.69 922.01 391.48
  Q 924.49 389.47 927.74 387.49
  A 1.13 1.13 0.0 0 0 927.61 385.49
  Q 916.77 380.69 905.78 377.79
  Q 868.16 367.86 831.86 363.73
  Q 812.35 361.51 799.74 361.80
  Q 775.56 362.37 752.28 362.28
  C 743.03 362.25 734.41 362.72 726.25 364.02
  Q 725.33 364.16 725.76 364.71
  Z
  M 1230.73 447.83
  Q 1230.75 447.95 1230.73 448.10
  A 0.58 0.57 34.2 0 0 1230.81 448.51
  Q 1241.91 466.25 1252.20 485.39
  Q 1252.65 486.21 1252.87 485.30
  Q 1253.89 481.25 1255.27 476.99
  Q 1255.81 475.31 1256.54 473.65
  Q 1257.26 472.03 1257.82 470.29
  Q 1258.65 467.76 1259.94 465.51
  Q 1261.15 463.40 1262.23 461.19
  Q 1262.85 459.93 1263.80 458.95
  Q 1265.16 457.56 1266.16 455.86
  Q 1267.10 454.26 1268.61 453.16
  Q 1272.08 450.63 1275.25 447.93
  Q 1277.12 446.34 1279.37 445.22
  C 1282.52 443.65 1284.94 441.63 1288.50 440.89
  Q 1289.07 440.78 1289.01 440.21
  Q 1288.84 438.44 1287.89 437.07
  Q 1283.55 423.12 1276.42 411.83
  Q 1268.87 399.88 1255.58 380.98
  Q 1250.29 373.46 1243.43 371.67
  Q 1235.56 369.62 1220.66 368.48
  C 1218.16 368.28 1215.03 368.34 1212.73 369.18
  C 1203.12 372.69 1194.87 379.34 1186.39 385.28
  A 1.64 1.64 0.0 0 0 1186.18 387.79
  Q 1194.74 396.20 1202.16 406.34
  Q 1217.54 427.35 1230.10 447.40
  Q 1230.23 447.61 1230.73 447.83
  Z
  M 1161.86 614.12
  C 1162.14 615.08 1162.29 616.21 1162.71 617.09
  Q 1163.62 619.04 1163.86 616.90
  C 1166.72 590.79 1174.24 564.05 1190.76 543.00
  Q 1195.73 536.67 1204.78 531.88
  C 1212.73 527.68 1219.57 525.49 1228.81 525.65
  A 1.49 1.48 80.0 0 0 1230.22 523.63
  C 1229.65 522.15 1228.54 520.76 1227.94 519.42
  Q 1223.44 509.37 1220.30 503.46
  Q 1204.94 474.56 1186.31 446.83
  C 1177.76 434.09 1168.26 421.82 1159.10 409.43
  A 0.87 0.86 -41.4 0 0 1157.78 409.35
  Q 1129.22 439.06 1105.02 473.00
  C 1104.17 474.20 1103.89 475.50 1104.69 476.75
  Q 1129.12 514.87 1145.82 559.73
  Q 1152.85 578.61 1156.36 593.38
  Q 1159.05 604.73 1161.86 614.12
  Z
  M 1289.49 563.04
  Q 1289.46 563.07 1289.67 563.61
  Q 1298.25 585.24 1304.18 603.26
  Q 1305.64 607.68 1306.75 613.26
  Q 1306.95 614.28 1307.94 614.89
  A 0.79 0.79 0.0 0 0 1309.14 614.24
  Q 1309.70 598.77 1310.77 583.25
  Q 1311.25 576.31 1310.77 559.08
  Q 1310.83 541.70 1308.31 521.49
  Q 1305.61 499.78 1301.64 482.20
  Q 1301.49 481.53 1300.83 481.35
  Q 1298.34 480.68 1295.34 480.79
  Q 1294.79 480.81 1294.37 481.16
  Q 1291.22 483.79 1290.04 486.27
  C 1287.49 491.63 1286.04 496.81 1284.49 502.48
  Q 1283.64 505.56 1283.12 508.75
  C 1281.41 519.14 1279.85 529.52 1279.73 539.88
  A 6.00 5.89 -57.2 0 0 1280.24 542.34
  L 1289.49 563.04
  Z
  M 1140.16 787.30
  Q 1141.44 753.25 1140.37 721.00
  Q 1139.19 685.29 1133.05 647.90
  Q 1129.27 624.92 1121.12 598.75
  C 1113.58 574.54 1105.41 551.86 1093.67 529.63
  C 1090.31 523.27 1085.73 517.09 1081.64 510.84
  A 1.68 1.68 0.0 0 0 1078.80 510.89
  Q 1054.98 550.36 1033.87 591.26
  Q 1007.07 643.21 976.33 713.59
  Q 972.40 722.59 968.45 731.98
  Q 935.84 809.61 899.41 893.22
  Q 895.51 902.17 891.13 911.43
  Q 882.61 929.48 873.55 947.77
  Q 863.40 968.25 851.24 987.16
  C 842.85 1000.22 833.20 1011.47 822.23 1023.31
  Q 821.51 1024.09 820.91 1024.95
  A 1.42 1.41 19.2 0 0 821.99 1027.17
  Q 853.40 1029.13 884.09 1025.60
  Q 920.76 1021.37 956.79 1011.75
  Q 966.49 1009.16 976.03 1006.05
  Q 1008.95 995.33 1032.64 984.99
  Q 1069.25 969.00 1115.66 940.68
  Q 1119.65 938.25 1123.94 936.86
  A 2.33 2.32 79.4 0 0 1125.55 934.50
  C 1125.04 926.06 1125.81 918.76 1127.70 909.72
  Q 1128.99 903.58 1129.88 897.62
  Q 1138.08 842.72 1140.16 787.30
  Z
  M 1191.13 646.13
  C 1189.67 657.61 1189.23 668.35 1188.80 679.74
  Q 1188.47 688.34 1188.38 699.65
  Q 1188.04 741.64 1188.00 783.60
  Q 1187.99 793.35 1187.71 803.65
  Q 1187.08 827.44 1186.58 854.04
  Q 1186.35 866.22 1187.13 878.88
  Q 1187.22 880.22 1188.08 879.19
  Q 1198.90 866.28 1208.33 854.10
  Q 1226.11 831.13 1249.35 799.03
  Q 1256.97 788.50 1260.59 782.31
  Q 1276.26 755.54 1280.11 724.79
  C 1283.01 701.69 1283.11 678.47 1278.66 656.00
  C 1272.53 625.08 1256.19 593.37 1232.71 572.04
  Q 1228.76 568.45 1223.09 567.38
  C 1220.13 566.82 1217.21 569.34 1215.62 571.43
  Q 1208.71 580.54 1204.30 592.50
  Q 1198.83 607.36 1197.19 613.54
  Q 1193.52 627.39 1191.13 646.13
  Z
  M 481.76 732.37
  Q 473.83 735.73 465.48 739.34
  Q 465.00 739.54 464.86 739.94
  Q 464.66 740.48 465.07 740.93
  Q 465.44 741.35 465.99 741.33
  C 472.09 741.14 477.54 737.86 483.60 737.75
  C 488.47 737.66 494.10 739.51 497.25 739.59
  Q 505.81 739.81 513.28 735.41
  C 518.17 732.52 515.61 725.55 510.59 725.22
  Q 504.76 724.84 500.20 726.07
  Q 490.43 728.70 481.76 732.37
  Z
  M 482.09 742.58
  L 482.34 744.10
  A 0.43 0.43 0.0 0 0 482.83 744.45
  L 483.00 744.42
  A 9.36 1.19 -9.2 0 0 492.05 741.75
  L 492.04 741.73
  A 9.36 1.19 -9.2 0 0 482.61 742.06
  L 482.45 742.08
  A 0.43 0.43 0.0 0 0 482.09 742.58
  Z
  M 574.03 752.90
  C 576.81 761.41 579.25 770.06 582.20 778.29
  Q 600.64 829.68 617.99 881.52
  Q 621.11 890.86 624.49 900.39
  Q 637.30 936.53 649.10 971.91
  C 651.30 978.49 653.82 984.79 655.81 991.34
  C 660.28 1006.10 665.87 1020.66 671.20 1035.04
  A 1.95 1.95 0.0 0 0 673.77 1036.17
  Q 692.29 1028.53 712.39 1025.34
  A 1.87 1.87 0.0 0 0 713.80 1022.73
  Q 709.54 1013.10 705.61 1003.47
  Q 690.40 966.22 669.83 912.84
  C 665.31 901.12 660.01 889.01 655.33 877.16
  Q 631.16 816.00 606.52 755.03
  Q 604.28 749.48 601.84 743.91
  Q 600.34 740.48 596.73 741.51
  L 574.77 747.85
  A 2.01 2.01 0.0 0 0 573.34 750.09
  Q 573.56 751.46 574.03 752.90
  Z
  M 551.01 829.91
  Q 555.08 842.81 559.18 854.61
  Q 573.30 895.20 585.85 932.82
  C 589.14 942.67 592.16 953.22 595.42 962.95
  Q 606.98 997.40 617.76 1031.03
  Q 620.88 1040.77 623.99 1049.97
  C 643.48 1107.72 661.54 1163.36 679.79 1220.19
  Q 680.00 1220.85 680.52 1221.37
  Q 681.44 1222.29 682.39 1221.41
  Q 697.84 1207.11 716.35 1195.05
  Q 732.89 1184.28 751.28 1179.27
  Q 763.54 1175.93 776.80 1176.60
  A 1.36 1.35 2.7 0 0 778.23 1175.31
  Q 778.29 1173.89 777.58 1172.58
  C 772.49 1163.20 768.08 1154.65 764.23 1145.19
  C 755.53 1123.83 746.18 1101.77 735.75 1081.35
  C 734.60 1079.11 732.56 1080.33 730.85 1080.81
  Q 696.72 1090.49 662.79 1103.87
  A 0.93 0.93 0.0 0 1 661.57 1103.31
  Q 647.21 1061.76 633.94 1020.10
  Q 630.88 1010.51 627.74 1001.09
  C 619.33 975.85 611.58 951.03 603.49 926.24
  Q 587.34 876.79 571.28 826.26
  C 564.60 805.27 557.12 784.49 549.95 763.66
  C 549.06 761.09 548.51 758.39 547.98 755.75
  A 1.79 1.78 77.4 0 0 545.81 754.37
  Q 538.34 756.24 531.26 758.71
  Q 526.23 760.46 527.76 765.61
  Q 529.56 771.70 531.59 776.73
  Q 543.66 806.66 551.01 829.91
  Z
  M 809.38 1368.15
  A 1.49 1.49 0.0 0 0 811.02 1366.40
  C 810.01 1360.84 809.23 1354.87 808.16 1349.56
  Q 794.60 1282.60 782.14 1213.99
  Q 781.56 1210.77 778.25 1211.75
  Q 762.29 1216.46 746.42 1222.75
  Q 742.30 1224.38 728.40 1231.18
  Q 718.79 1235.88 710.13 1239.08
  A 1.28 1.28 0.0 0 0 709.89 1241.36
  Q 720.45 1248.06 728.48 1257.16
  Q 738.54 1268.58 748.35 1281.73
  Q 771.76 1313.12 789.24 1345.89
  C 792.52 1352.04 796.20 1358.55 800.82 1363.62
  Q 804.40 1367.54 809.38 1368.15
  Z"
                />
                <path fill="white" d="
  M 298.91 1229.37
  Q 297.18 1228.46 294.25 1228.31
  Q 274.88 1227.30 254.20 1223.60
  Q 239.58 1220.98 219.13 1216.27
  Q 201.06 1212.12 185.70 1203.79
  C 176.63 1198.87 169.82 1192.78 162.16 1185.42
  Q 162.03 1185.30 161.85 1185.30
  Q 161.71 1185.30 161.57 1185.29
  Q 161.61 1185.05 161.62 1184.83
  Q 161.63 1184.51 161.50 1184.24
  C 152.17 1166.04 156.26 1146.87 166.11 1129.62
  C 175.41 1113.33 187.20 1099.55 201.19 1086.54
  C 226.80 1062.72 254.23 1043.03 284.33 1022.69
  Q 292.54 1017.14 301.10 1011.81
  Q 341.25 986.81 360.33 976.35
  Q 383.45 963.68 405.78 951.12
  C 420.10 943.06 435.03 935.40 448.91 926.31
  C 450.43 925.32 451.51 925.90 452.56 927.07
  Q 459.54 934.83 465.68 941.65
  Q 466.56 942.62 465.56 943.47
  Q 440.19 965.04 415.30 985.55
  Q 409.07 990.68 385.16 1012.16
  Q 359.78 1034.94 342.84 1053.65
  C 334.54 1062.82 327.15 1072.13 320.01 1082.45
  Q 313.00 1092.60 311.11 1102.02
  C 308.49 1115.08 316.91 1127.56 327.83 1134.16
  Q 334.47 1138.18 346.77 1141.92
  Q 365.58 1147.63 384.32 1149.95
  Q 438.69 1156.66 490.97 1154.17
  Q 536.28 1152.02 579.05 1146.36
  A 2.71 2.71 0.0 0 1 582.00 1148.27
  Q 588.31 1169.18 595.08 1190.17
  C 596.31 1194.00 597.18 1198.21 598.09 1202.19
  Q 598.37 1203.45 597.11 1203.72
  Q 575.27 1208.45 555.37 1211.51
  Q 539.05 1214.03 535.60 1214.62
  C 511.65 1218.74 492.09 1220.57 460.17 1224.09
  Q 449.74 1225.25 440.28 1225.98
  Q 397.95 1229.25 355.49 1229.70
  Q 348.49 1229.77 335.51 1229.38
  Q 317.22 1228.83 298.91 1229.37
  Z"
                />
                <path fill="#DD6F20" d="
  M 1183.90 1283.01
  Q 1183.01 1283.14 1182.59 1283.59
  Q 1175.22 1291.33 1167.76 1298.98
  C 1160.23 1306.68 1152.76 1313.06 1145.11 1320.48
  C 1136.90 1328.46 1127.84 1335.76 1119.44 1343.33
  Q 1113.83 1348.38 1108.54 1352.28
  Q 1098.45 1359.70 1088.47 1367.46
  Q 1080.37 1373.75 1072.43 1378.96
  Q 1041.43 1399.28 1014.77 1412.85
  Q 968.69 1436.31 925.25 1450.39
  Q 884.04 1463.76 835.02 1473.09
  Q 768.68 1485.72 704.44 1485.76
  Q 643.52 1485.81 576.38 1481.61
  Q 569.74 1481.20 561.51 1480.26
  Q 526.16 1476.24 489.05 1468.88
  C 473.42 1465.78 457.42 1463.00 441.97 1460.89
  Q 439.26 1460.52 436.63 1460.76
  Q 434.93 1460.91 436.16 1459.73
  Q 444.76 1451.51 453.44 1443.27
  Q 459.39 1437.61 464.50 1430.49
  Q 465.24 1429.46 466.45 1429.81
  Q 474.43 1432.06 482.57 1433.33
  Q 520.55 1439.28 549.59 1442.53
  Q 566.34 1444.41 601.03 1447.01
  Q 611.32 1447.77 620.99 1448.16
  Q 685.00 1450.70 741.37 1447.12
  Q 767.78 1445.44 812.63 1441.07
  Q 825.04 1441.20 832.01 1440.58
  Q 839.61 1439.89 844.33 1433.31
  Q 855.76 1430.46 867.24 1427.84
  Q 879.18 1425.11 886.64 1423.04
  Q 941.84 1407.70 992.29 1385.56
  Q 1031.54 1368.33 1065.21 1349.95
  Q 1099.89 1331.02 1133.92 1308.20
  Q 1161.12 1289.96 1183.77 1270.72
  Q 1184.18 1270.37 1184.09 1269.72
  Q 1184.57 1269.70 1184.94 1269.46
  Q 1198.08 1261.09 1209.00 1249.65
  Q 1209.64 1248.99 1210.49 1249.31
  L 1210.75 1249.41
  A 0.53 0.53 0.0 0 1 1210.97 1250.24
  L 1183.90 1283.01
  Z"
                />
            </svg>


            {/* ICB WHITE LOGO LOWER RIGHT */}
            <svg x="464" y="420" width="50" height="50" transform="rotate(270 100 410)" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 1409.00 1487.00">
                <g strokeWidth="2.00" fill="none" strokeLinecap="butt">
                </g>
                <path fill="white" d="
  M 844.33 1433.31
  Q 839.61 1439.89 832.01 1440.58
  Q 825.04 1441.20 812.63 1441.07
  Q 808.22 1424.63 800.85 1410.03
  A 2.24 2.23 68.7 0 0 798.26 1408.89
  L 781.05 1413.65
  Q 780.54 1413.79 780.08 1413.56
  Q 777.64 1412.34 776.79 1409.75
  Q 764.31 1371.68 738.90 1341.36
  Q 730.66 1331.54 718.72 1322.75
  Q 708.63 1315.32 703.02 1312.19
  Q 677.86 1298.14 648.75 1291.73
  C 646.78 1291.30 645.61 1290.16 645.25 1288.16
  Q 642.27 1271.88 638.98 1260.26
  Q 624.84 1210.28 602.67 1140.73
  Q 599.63 1131.18 596.70 1121.63
  C 582.44 1075.22 568.08 1031.42 552.19 987.62
  A 3.85 3.79 -82.3 0 0 550.86 985.85
  C 543.59 980.55 537.69 976.49 529.69 969.81
  Q 511.85 954.91 491.07 933.95
  C 476.78 919.53 463.91 903.66 451.47 887.51
  Q 391.54 809.68 364.52 714.53
  Q 362.73 708.22 357.97 689.45
  Q 355.48 679.63 353.95 669.86
  Q 351.26 652.71 349.14 637.72
  C 347.03 622.85 345.72 607.69 345.23 592.36
  Q 342.98 522.32 360.76 453.73
  C 372.23 409.51 389.38 366.59 412.50 327.19
  C 417.52 318.63 422.50 310.26 427.94 302.06
  Q 458.40 256.10 495.49 219.05
  Q 508.17 206.39 526.09 190.93
  Q 533.57 184.49 541.64 178.34
  C 571.85 155.36 604.80 135.86 638.94 119.73
  Q 651.62 113.74 675.61 104.25
  Q 685.14 100.47 694.44 97.49
  Q 742.69 82.02 791.53 74.92
  Q 805.92 72.83 831.32 70.96
  Q 873.21 67.89 912.75 72.33
  Q 922.87 73.47 932.81 74.71
  Q 942.36 75.90 952.49 77.91
  Q 969.25 81.24 984.72 84.52
  Q 989.37 85.51 994.74 87.08
  Q 1015.34 93.13 1033.88 99.68
  Q 1045.88 103.91 1061.60 110.56
  Q 1116.94 133.97 1164.98 170.12
  Q 1188.30 187.66 1208.98 206.80
  Q 1261.38 255.30 1297.00 314.60
  Q 1306.60 330.58 1315.64 347.33
  C 1321.74 358.61 1326.80 370.46 1331.99 382.17
  Q 1338.57 397.03 1346.95 420.55
  Q 1350.60 430.80 1353.13 439.80
  Q 1368.06 492.96 1371.39 540.64
  Q 1374.13 579.99 1370.78 620.68
  Q 1369.24 639.31 1364.34 665.97
  C 1348.88 749.99 1311.84 828.94 1258.75 895.88
  Q 1252.31 904.00 1246.18 910.98
  Q 1225.43 934.60 1202.14 957.52
  C 1193.52 966.01 1183.31 973.26 1173.49 980.44
  Q 1144.49 1001.62 1116.83 1017.65
  Q 1075.29 1041.73 1030.31 1057.05
  Q 1024.74 1058.95 1019.91 1062.90
  Q 973.77 1100.71 914.63 1125.93
  Q 888.20 1137.20 879.89 1140.14
  Q 860.73 1146.91 844.70 1153.70
  A 1.75 1.74 -18.9 0 1 842.34 1152.55
  Q 839.41 1141.73 834.95 1131.19
  Q 829.10 1117.38 822.23 1104.52
  Q 821.65 1103.43 822.86 1103.18
  C 835.24 1100.70 847.32 1096.84 859.59 1093.87
  Q 863.07 1093.03 865.39 1090.95
  Q 867.06 1089.47 864.84 1089.67
  Q 846.07 1091.35 827.01 1088.95
  Q 811.53 1087.01 794.23 1085.23
  A 1.51 1.45 -55.9 0 0 793.34 1085.42
  Q 792.03 1086.15 792.55 1087.63
  C 799.51 1107.21 808.72 1125.74 816.44 1145.01
  Q 820.82 1155.94 826.17 1166.04
  Q 835.08 1182.88 844.03 1198.97
  C 848.75 1207.45 850.82 1214.91 848.08 1224.00
  C 844.32 1236.48 839.63 1249.52 836.26 1263.21
  C 833.48 1274.51 831.60 1287.04 830.32 1298.31
  Q 828.27 1316.25 831.79 1334.41
  Q 834.79 1349.96 841.96 1367.00
  C 845.86 1376.29 848.35 1386.02 844.29 1395.77
  Q 843.24 1398.31 840.60 1399.79
  Q 836.56 1402.06 831.92 1403.98
  A 0.71 0.70 -23.4 0 0 831.54 1404.92
  L 844.33 1433.31
  Z
  M 970.92 146.55
  C 927.02 134.76 880.72 130.74 835.94 133.61
  Q 819.27 134.68 799.47 136.75
  A 0.78 0.77 83.8 0 0 798.78 137.61
  C 798.92 138.78 800.23 139.43 801.28 139.52
  Q 810.52 140.32 813.00 140.86
  Q 833.83 145.46 854.51 151.12
  Q 876.07 157.03 894.90 165.42
  Q 906.14 170.43 913.55 175.24
  C 925.15 182.75 936.84 189.10 948.72 195.71
  C 961.56 202.86 976.97 210.23 987.90 215.88
  Q 1005.49 224.99 1020.76 233.33
  Q 1030.28 238.54 1037.21 240.75
  Q 1045.78 243.49 1055.07 244.53
  C 1061.86 245.29 1070.19 243.62 1075.46 238.84
  C 1082.43 232.51 1085.06 221.94 1084.45 212.50
  C 1083.43 196.92 1073.52 188.08 1060.63 181.38
  Q 1045.75 173.65 1032.06 167.57
  C 1012.19 158.74 991.85 152.18 970.92 146.55
  Z
  M 555.22 255.24
  Q 555.77 256.14 556.75 255.74
  Q 570.93 250.01 581.22 246.32
  C 587.73 243.98 595.47 242.85 602.61 240.37
  Q 609.95 237.82 617.32 235.91
  Q 618.63 235.57 620.93 234.36
  Q 623.46 233.03 625.05 233.99
  Q 610.41 241.37 596.06 248.33
  Q 590.29 251.13 563.41 267.02
  C 558.07 270.18 553.94 274.02 548.99 277.54
  Q 543.44 281.51 538.42 286.01
  Q 520.82 301.78 512.10 311.10
  Q 499.41 324.66 487.82 339.80
  Q 483.70 345.18 480.69 350.96
  Q 472.45 361.74 463.20 377.43
  Q 447.73 403.72 439.85 420.53
  Q 439.14 422.04 439.30 423.72
  A 0.66 0.65 65.2 0 0 440.41 424.13
  Q 474.01 391.30 516.84 371.58
  Q 539.08 361.34 559.23 354.80
  C 599.18 341.82 642.93 333.10 688.12 327.69
  Q 712.36 324.79 739.69 324.47
  Q 768.05 324.12 789.01 325.05
  Q 812.04 326.08 835.87 329.46
  Q 869.54 334.24 900.14 341.88
  Q 925.79 348.28 954.43 360.29
  C 955.43 360.71 956.77 360.65 957.53 359.78
  Q 965.13 350.94 972.32 339.80
  Q 990.93 310.95 983.72 277.09
  Q 981.90 268.56 976.29 262.65
  Q 972.72 258.89 963.91 254.22
  Q 935.23 238.97 902.29 220.74
  Q 890.65 214.30 884.63 211.39
  Q 869.41 204.01 854.85 198.88
  Q 832.19 190.90 806.98 184.11
  Q 797.53 181.57 787.97 179.60
  Q 771.91 176.28 758.01 174.39
  Q 757.29 174.29 757.12 174.90
  Q 754.79 173.46 753.50 173.28
  Q 723.07 169.00 692.99 168.98
  Q 691.07 168.98 689.38 169.56
  Q 679.38 173.00 669.78 177.40
  Q 643.56 189.42 621.39 203.01
  Q 610.37 209.77 596.24 219.22
  C 583.12 228.00 571.91 237.46 560.12 247.48
  Q 556.92 250.20 554.48 253.89
  A 1.13 1.06 46.3 0 0 554.49 255.07
  Q 554.62 255.29 555.22 255.24
  Z
  M 1154.15 246.49
  C 1146.87 240.00 1138.14 233.41 1129.81 227.39
  Q 1128.77 226.63 1127.55 226.75
  Q 1126.24 226.88 1126.08 228.18
  Q 1124.75 239.06 1120.59 249.67
  C 1116.18 260.90 1108.95 269.34 1098.73 275.29
  C 1094.28 277.88 1088.44 279.89 1083.20 281.81
  A 1.04 1.04 0.0 0 0 1082.90 283.59
  Q 1119.96 314.01 1152.46 348.48
  A 2.38 2.37 52.8 0 0 1155.42 348.89
  Q 1188.12 329.29 1225.60 329.94
  A 1.04 1.04 0.0 0 0 1226.54 328.41
  Q 1223.00 321.77 1218.23 315.59
  Q 1188.72 277.32 1154.15 246.49
  Z
  M 1109.14 398.18
  C 1115.50 390.86 1122.25 383.83 1128.02 375.97
  A 0.84 0.83 43.4 0 0 1127.99 374.95
  Q 1119.78 364.79 1111.82 357.36
  Q 1093.53 340.29 1074.63 324.66
  Q 1066.35 317.81 1058.73 312.10
  Q 1042.99 300.31 1028.41 289.39
  Q 1027.23 288.51 1026.20 289.61
  A 1.70 1.68 -24.7 0 0 1025.74 290.84
  Q 1027.44 326.89 1006.71 359.50
  Q 999.91 370.20 994.10 377.40
  Q 993.40 378.27 993.19 379.33
  Q 992.92 380.74 994.11 381.44
  Q 1022.86 398.25 1048.44 420.11
  Q 1061.18 430.99 1074.19 442.05
  Q 1075.77 443.39 1076.92 441.67
  Q 1087.46 425.89 1098.89 410.82
  Q 1103.91 404.20 1109.14 398.18
  Z
  M 725.76 364.71
  Q 725.41 364.42 724.99 364.41
  Q 716.41 364.13 708.98 365.21
  Q 685.34 368.65 662.18 372.70
  Q 650.82 374.69 640.05 377.37
  Q 630.91 379.64 621.39 381.98
  Q 599.15 387.42 577.53 395.01
  Q 544.46 406.62 513.86 425.12
  Q 513.09 425.58 512.51 426.25
  Q 511.97 426.11 511.46 426.45
  Q 491.80 439.81 475.03 454.95
  Q 470.12 459.38 466.62 463.68
  C 460.11 471.66 452.78 479.34 447.24 487.53
  Q 439.44 499.05 432.26 512.35
  C 426.76 522.54 422.76 534.06 419.65 545.19
  Q 417.48 552.99 416.28 562.42
  C 414.19 578.80 413.66 593.60 414.72 610.20
  C 415.92 628.98 417.47 645.80 421.42 664.40
  Q 425.23 682.39 429.69 699.08
  C 433.18 712.17 438.65 725.43 443.46 738.16
  A 2.58 2.58 0.0 0 0 446.89 739.63
  Q 457.15 735.25 467.55 731.67
  C 471.55 730.29 475.62 728.00 479.48 725.90
  Q 490.40 720.00 502.54 717.01
  C 505.94 716.17 509.72 716.54 513.24 716.46
  Q 515.00 716.42 516.53 715.39
  Q 523.94 710.40 530.03 705.58
  C 531.87 704.12 534.90 702.61 537.13 701.98
  Q 553.00 697.45 561.90 693.73
  Q 567.18 691.52 572.71 689.06
  C 580.57 685.56 589.41 687.25 596.75 692.40
  Q 604.09 697.55 608.65 705.25
  A 1.53 1.52 55.0 0 0 610.93 705.66
  Q 620.11 698.14 631.61 697.08
  C 636.16 696.67 641.68 696.26 646.08 697.16
  Q 656.37 699.25 662.67 708.56
  Q 669.84 719.17 672.54 729.02
  C 675.00 738.02 675.61 748.80 676.98 758.57
  A 1.96 1.96 0.0 0 1 674.34 760.68
  L 667.27 757.99
  A 1.00 0.99 -59.6 0 0 666.14 758.29
  Q 665.76 758.75 666.08 759.27
  C 669.27 764.59 672.01 770.04 674.20 775.80
  Q 684.97 804.10 698.31 837.96
  Q 729.99 918.38 761.40 999.65
  A 0.86 0.86 0.0 0 0 762.94 999.79
  C 766.47 994.01 769.70 988.13 773.77 982.77
  C 779.74 974.88 785.02 966.73 792.34 959.58
  Q 795.69 956.31 800.65 950.02
  Q 822.02 922.88 839.55 895.19
  C 863.98 856.58 884.50 813.12 904.90 767.63
  Q 921.09 731.54 936.85 696.21
  Q 967.84 626.76 997.64 570.55
  Q 1005.91 554.95 1017.24 534.35
  C 1026.74 517.08 1037.16 499.81 1047.27 483.43
  Q 1049.57 479.70 1052.94 476.39
  A 1.41 1.41 0.0 0 0 1053.01 474.47
  C 1042.61 462.51 1031.75 451.35 1019.66 441.39
  Q 1011.55 434.72 1002.04 427.43
  Q 987.90 416.59 969.70 407.44
  Q 967.74 406.45 966.31 407.89
  C 962.92 411.26 959.62 414.87 955.99 417.81
  Q 928.59 440.05 910.24 451.47
  Q 881.01 469.66 853.56 486.81
  C 845.44 491.88 835.34 497.02 827.07 502.08
  C 819.98 506.41 813.15 510.71 806.02 514.72
  Q 773.41 533.05 741.64 552.36
  Q 689.34 584.15 641.59 619.50
  C 633.47 625.51 626.12 632.13 618.51 638.31
  C 610.27 644.99 602.84 651.97 594.99 659.03
  Q 589.24 664.21 583.25 665.72
  Q 551.80 673.64 520.65 686.19
  C 517.36 687.52 513.86 689.09 510.92 690.93
  C 498.65 698.61 486.02 706.51 473.09 713.51
  A 1.16 1.16 0.0 0 1 471.40 712.27
  Q 471.94 709.42 473.58 707.08
  Q 480.53 697.16 487.93 688.10
  Q 490.41 685.06 493.32 682.06
  Q 548.87 624.93 614.13 579.79
  Q 621.91 574.41 630.64 568.76
  Q 644.89 559.55 659.16 550.53
  Q 677.20 539.12 704.47 523.13
  Q 713.44 517.87 722.08 513.04
  Q 774.88 483.56 827.38 453.97
  Q 854.99 438.42 887.10 417.69
  C 899.39 409.77 910.64 400.69 922.01 391.48
  Q 924.49 389.47 927.74 387.49
  A 1.13 1.13 0.0 0 0 927.61 385.49
  Q 916.77 380.69 905.78 377.79
  Q 868.16 367.86 831.86 363.73
  Q 812.35 361.51 799.74 361.80
  Q 775.56 362.37 752.28 362.28
  C 743.03 362.25 734.41 362.72 726.25 364.02
  Q 725.33 364.16 725.76 364.71
  Z
  M 1230.73 447.83
  Q 1230.75 447.95 1230.73 448.10
  A 0.58 0.57 34.2 0 0 1230.81 448.51
  Q 1241.91 466.25 1252.20 485.39
  Q 1252.65 486.21 1252.87 485.30
  Q 1253.89 481.25 1255.27 476.99
  Q 1255.81 475.31 1256.54 473.65
  Q 1257.26 472.03 1257.82 470.29
  Q 1258.65 467.76 1259.94 465.51
  Q 1261.15 463.40 1262.23 461.19
  Q 1262.85 459.93 1263.80 458.95
  Q 1265.16 457.56 1266.16 455.86
  Q 1267.10 454.26 1268.61 453.16
  Q 1272.08 450.63 1275.25 447.93
  Q 1277.12 446.34 1279.37 445.22
  C 1282.52 443.65 1284.94 441.63 1288.50 440.89
  Q 1289.07 440.78 1289.01 440.21
  Q 1288.84 438.44 1287.89 437.07
  Q 1283.55 423.12 1276.42 411.83
  Q 1268.87 399.88 1255.58 380.98
  Q 1250.29 373.46 1243.43 371.67
  Q 1235.56 369.62 1220.66 368.48
  C 1218.16 368.28 1215.03 368.34 1212.73 369.18
  C 1203.12 372.69 1194.87 379.34 1186.39 385.28
  A 1.64 1.64 0.0 0 0 1186.18 387.79
  Q 1194.74 396.20 1202.16 406.34
  Q 1217.54 427.35 1230.10 447.40
  Q 1230.23 447.61 1230.73 447.83
  Z
  M 1161.86 614.12
  C 1162.14 615.08 1162.29 616.21 1162.71 617.09
  Q 1163.62 619.04 1163.86 616.90
  C 1166.72 590.79 1174.24 564.05 1190.76 543.00
  Q 1195.73 536.67 1204.78 531.88
  C 1212.73 527.68 1219.57 525.49 1228.81 525.65
  A 1.49 1.48 80.0 0 0 1230.22 523.63
  C 1229.65 522.15 1228.54 520.76 1227.94 519.42
  Q 1223.44 509.37 1220.30 503.46
  Q 1204.94 474.56 1186.31 446.83
  C 1177.76 434.09 1168.26 421.82 1159.10 409.43
  A 0.87 0.86 -41.4 0 0 1157.78 409.35
  Q 1129.22 439.06 1105.02 473.00
  C 1104.17 474.20 1103.89 475.50 1104.69 476.75
  Q 1129.12 514.87 1145.82 559.73
  Q 1152.85 578.61 1156.36 593.38
  Q 1159.05 604.73 1161.86 614.12
  Z
  M 1289.49 563.04
  Q 1289.46 563.07 1289.67 563.61
  Q 1298.25 585.24 1304.18 603.26
  Q 1305.64 607.68 1306.75 613.26
  Q 1306.95 614.28 1307.94 614.89
  A 0.79 0.79 0.0 0 0 1309.14 614.24
  Q 1309.70 598.77 1310.77 583.25
  Q 1311.25 576.31 1310.77 559.08
  Q 1310.83 541.70 1308.31 521.49
  Q 1305.61 499.78 1301.64 482.20
  Q 1301.49 481.53 1300.83 481.35
  Q 1298.34 480.68 1295.34 480.79
  Q 1294.79 480.81 1294.37 481.16
  Q 1291.22 483.79 1290.04 486.27
  C 1287.49 491.63 1286.04 496.81 1284.49 502.48
  Q 1283.64 505.56 1283.12 508.75
  C 1281.41 519.14 1279.85 529.52 1279.73 539.88
  A 6.00 5.89 -57.2 0 0 1280.24 542.34
  L 1289.49 563.04
  Z
  M 1140.16 787.30
  Q 1141.44 753.25 1140.37 721.00
  Q 1139.19 685.29 1133.05 647.90
  Q 1129.27 624.92 1121.12 598.75
  C 1113.58 574.54 1105.41 551.86 1093.67 529.63
  C 1090.31 523.27 1085.73 517.09 1081.64 510.84
  A 1.68 1.68 0.0 0 0 1078.80 510.89
  Q 1054.98 550.36 1033.87 591.26
  Q 1007.07 643.21 976.33 713.59
  Q 972.40 722.59 968.45 731.98
  Q 935.84 809.61 899.41 893.22
  Q 895.51 902.17 891.13 911.43
  Q 882.61 929.48 873.55 947.77
  Q 863.40 968.25 851.24 987.16
  C 842.85 1000.22 833.20 1011.47 822.23 1023.31
  Q 821.51 1024.09 820.91 1024.95
  A 1.42 1.41 19.2 0 0 821.99 1027.17
  Q 853.40 1029.13 884.09 1025.60
  Q 920.76 1021.37 956.79 1011.75
  Q 966.49 1009.16 976.03 1006.05
  Q 1008.95 995.33 1032.64 984.99
  Q 1069.25 969.00 1115.66 940.68
  Q 1119.65 938.25 1123.94 936.86
  A 2.33 2.32 79.4 0 0 1125.55 934.50
  C 1125.04 926.06 1125.81 918.76 1127.70 909.72
  Q 1128.99 903.58 1129.88 897.62
  Q 1138.08 842.72 1140.16 787.30
  Z
  M 1191.13 646.13
  C 1189.67 657.61 1189.23 668.35 1188.80 679.74
  Q 1188.47 688.34 1188.38 699.65
  Q 1188.04 741.64 1188.00 783.60
  Q 1187.99 793.35 1187.71 803.65
  Q 1187.08 827.44 1186.58 854.04
  Q 1186.35 866.22 1187.13 878.88
  Q 1187.22 880.22 1188.08 879.19
  Q 1198.90 866.28 1208.33 854.10
  Q 1226.11 831.13 1249.35 799.03
  Q 1256.97 788.50 1260.59 782.31
  Q 1276.26 755.54 1280.11 724.79
  C 1283.01 701.69 1283.11 678.47 1278.66 656.00
  C 1272.53 625.08 1256.19 593.37 1232.71 572.04
  Q 1228.76 568.45 1223.09 567.38
  C 1220.13 566.82 1217.21 569.34 1215.62 571.43
  Q 1208.71 580.54 1204.30 592.50
  Q 1198.83 607.36 1197.19 613.54
  Q 1193.52 627.39 1191.13 646.13
  Z
  M 481.76 732.37
  Q 473.83 735.73 465.48 739.34
  Q 465.00 739.54 464.86 739.94
  Q 464.66 740.48 465.07 740.93
  Q 465.44 741.35 465.99 741.33
  C 472.09 741.14 477.54 737.86 483.60 737.75
  C 488.47 737.66 494.10 739.51 497.25 739.59
  Q 505.81 739.81 513.28 735.41
  C 518.17 732.52 515.61 725.55 510.59 725.22
  Q 504.76 724.84 500.20 726.07
  Q 490.43 728.70 481.76 732.37
  Z
  M 482.09 742.58
  L 482.34 744.10
  A 0.43 0.43 0.0 0 0 482.83 744.45
  L 483.00 744.42
  A 9.36 1.19 -9.2 0 0 492.05 741.75
  L 492.04 741.73
  A 9.36 1.19 -9.2 0 0 482.61 742.06
  L 482.45 742.08
  A 0.43 0.43 0.0 0 0 482.09 742.58
  Z
  M 574.03 752.90
  C 576.81 761.41 579.25 770.06 582.20 778.29
  Q 600.64 829.68 617.99 881.52
  Q 621.11 890.86 624.49 900.39
  Q 637.30 936.53 649.10 971.91
  C 651.30 978.49 653.82 984.79 655.81 991.34
  C 660.28 1006.10 665.87 1020.66 671.20 1035.04
  A 1.95 1.95 0.0 0 0 673.77 1036.17
  Q 692.29 1028.53 712.39 1025.34
  A 1.87 1.87 0.0 0 0 713.80 1022.73
  Q 709.54 1013.10 705.61 1003.47
  Q 690.40 966.22 669.83 912.84
  C 665.31 901.12 660.01 889.01 655.33 877.16
  Q 631.16 816.00 606.52 755.03
  Q 604.28 749.48 601.84 743.91
  Q 600.34 740.48 596.73 741.51
  L 574.77 747.85
  A 2.01 2.01 0.0 0 0 573.34 750.09
  Q 573.56 751.46 574.03 752.90
  Z
  M 551.01 829.91
  Q 555.08 842.81 559.18 854.61
  Q 573.30 895.20 585.85 932.82
  C 589.14 942.67 592.16 953.22 595.42 962.95
  Q 606.98 997.40 617.76 1031.03
  Q 620.88 1040.77 623.99 1049.97
  C 643.48 1107.72 661.54 1163.36 679.79 1220.19
  Q 680.00 1220.85 680.52 1221.37
  Q 681.44 1222.29 682.39 1221.41
  Q 697.84 1207.11 716.35 1195.05
  Q 732.89 1184.28 751.28 1179.27
  Q 763.54 1175.93 776.80 1176.60
  A 1.36 1.35 2.7 0 0 778.23 1175.31
  Q 778.29 1173.89 777.58 1172.58
  C 772.49 1163.20 768.08 1154.65 764.23 1145.19
  C 755.53 1123.83 746.18 1101.77 735.75 1081.35
  C 734.60 1079.11 732.56 1080.33 730.85 1080.81
  Q 696.72 1090.49 662.79 1103.87
  A 0.93 0.93 0.0 0 1 661.57 1103.31
  Q 647.21 1061.76 633.94 1020.10
  Q 630.88 1010.51 627.74 1001.09
  C 619.33 975.85 611.58 951.03 603.49 926.24
  Q 587.34 876.79 571.28 826.26
  C 564.60 805.27 557.12 784.49 549.95 763.66
  C 549.06 761.09 548.51 758.39 547.98 755.75
  A 1.79 1.78 77.4 0 0 545.81 754.37
  Q 538.34 756.24 531.26 758.71
  Q 526.23 760.46 527.76 765.61
  Q 529.56 771.70 531.59 776.73
  Q 543.66 806.66 551.01 829.91
  Z
  M 809.38 1368.15
  A 1.49 1.49 0.0 0 0 811.02 1366.40
  C 810.01 1360.84 809.23 1354.87 808.16 1349.56
  Q 794.60 1282.60 782.14 1213.99
  Q 781.56 1210.77 778.25 1211.75
  Q 762.29 1216.46 746.42 1222.75
  Q 742.30 1224.38 728.40 1231.18
  Q 718.79 1235.88 710.13 1239.08
  A 1.28 1.28 0.0 0 0 709.89 1241.36
  Q 720.45 1248.06 728.48 1257.16
  Q 738.54 1268.58 748.35 1281.73
  Q 771.76 1313.12 789.24 1345.89
  C 792.52 1352.04 796.20 1358.55 800.82 1363.62
  Q 804.40 1367.54 809.38 1368.15
  Z"
                />

                <path fill="white" d="
  M 298.91 1229.37
  Q 297.18 1228.46 294.25 1228.31
  Q 274.88 1227.30 254.20 1223.60
  Q 239.58 1220.98 219.13 1216.27
  Q 201.06 1212.12 185.70 1203.79
  C 176.63 1198.87 169.82 1192.78 162.16 1185.42
  Q 162.03 1185.30 161.85 1185.30
  Q 161.71 1185.30 161.57 1185.29
  Q 161.61 1185.05 161.62 1184.83
  Q 161.63 1184.51 161.50 1184.24
  C 152.17 1166.04 156.26 1146.87 166.11 1129.62
  C 175.41 1113.33 187.20 1099.55 201.19 1086.54
  C 226.80 1062.72 254.23 1043.03 284.33 1022.69
  Q 292.54 1017.14 301.10 1011.81
  Q 341.25 986.81 360.33 976.35
  Q 383.45 963.68 405.78 951.12
  C 420.10 943.06 435.03 935.40 448.91 926.31
  C 450.43 925.32 451.51 925.90 452.56 927.07
  Q 459.54 934.83 465.68 941.65
  Q 466.56 942.62 465.56 943.47
  Q 440.19 965.04 415.30 985.55
  Q 409.07 990.68 385.16 1012.16
  Q 359.78 1034.94 342.84 1053.65
  C 334.54 1062.82 327.15 1072.13 320.01 1082.45
  Q 313.00 1092.60 311.11 1102.02
  C 308.49 1115.08 316.91 1127.56 327.83 1134.16
  Q 334.47 1138.18 346.77 1141.92
  Q 365.58 1147.63 384.32 1149.95
  Q 438.69 1156.66 490.97 1154.17
  Q 536.28 1152.02 579.05 1146.36
  A 2.71 2.71 0.0 0 1 582.00 1148.27
  Q 588.31 1169.18 595.08 1190.17
  C 596.31 1194.00 597.18 1198.21 598.09 1202.19
  Q 598.37 1203.45 597.11 1203.72
  Q 575.27 1208.45 555.37 1211.51
  Q 539.05 1214.03 535.60 1214.62
  C 511.65 1218.74 492.09 1220.57 460.17 1224.09
  Q 449.74 1225.25 440.28 1225.98
  Q 397.95 1229.25 355.49 1229.70
  Q 348.49 1229.77 335.51 1229.38
  Q 317.22 1228.83 298.91 1229.37
  Z"
                />

                <path fill="#DD6F20" d="
  M 1183.90 1283.01
  Q 1183.01 1283.14 1182.59 1283.59
  Q 1175.22 1291.33 1167.76 1298.98
  C 1160.23 1306.68 1152.76 1313.06 1145.11 1320.48
  C 1136.90 1328.46 1127.84 1335.76 1119.44 1343.33
  Q 1113.83 1348.38 1108.54 1352.28
  Q 1098.45 1359.70 1088.47 1367.46
  Q 1080.37 1373.75 1072.43 1378.96
  Q 1041.43 1399.28 1014.77 1412.85
  Q 968.69 1436.31 925.25 1450.39
  Q 884.04 1463.76 835.02 1473.09
  Q 768.68 1485.72 704.44 1485.76
  Q 643.52 1485.81 576.38 1481.61
  Q 569.74 1481.20 561.51 1480.26
  Q 526.16 1476.24 489.05 1468.88
  C 473.42 1465.78 457.42 1463.00 441.97 1460.89
  Q 439.26 1460.52 436.63 1460.76
  Q 434.93 1460.91 436.16 1459.73
  Q 444.76 1451.51 453.44 1443.27
  Q 459.39 1437.61 464.50 1430.49
  Q 465.24 1429.46 466.45 1429.81
  Q 474.43 1432.06 482.57 1433.33
  Q 520.55 1439.28 549.59 1442.53
  Q 566.34 1444.41 601.03 1447.01
  Q 611.32 1447.77 620.99 1448.16
  Q 685.00 1450.70 741.37 1447.12
  Q 767.78 1445.44 812.63 1441.07
  Q 825.04 1441.20 832.01 1440.58
  Q 839.61 1439.89 844.33 1433.31
  Q 855.76 1430.46 867.24 1427.84
  Q 879.18 1425.11 886.64 1423.04
  Q 941.84 1407.70 992.29 1385.56
  Q 1031.54 1368.33 1065.21 1349.95
  Q 1099.89 1331.02 1133.92 1308.20
  Q 1161.12 1289.96 1183.77 1270.72
  Q 1184.18 1270.37 1184.09 1269.72
  Q 1184.57 1269.70 1184.94 1269.46
  Q 1198.08 1261.09 1209.00 1249.65
  Q 1209.64 1248.99 1210.49 1249.31
  L 1210.75 1249.41
  A 0.53 0.53 0.0 0 1 1210.97 1250.24
  L 1183.90 1283.01
  Z"
                />

            </svg>

        </svg >
    );
};
